:local(.mod_checkbox) {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 48px;
  left: 50%;
  //margin-top: -10px;
  margin-left: -10px;

  input[type=checkbox] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  .ui_checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAH1JREFUWAnt2LENgDAMRFFA7OH9t7InASmSs8BP4eLTQHPo9HRpcmfmdw1+nsHdVrW3C0ZEf454V9XqMV7QgnQvCipIBWjeDSpIBWjeDSpIBWjeDSpIBWjeDSpIBWjeDSpIBWjeDSpIBWjeDSpIBWh+31H3nTD94en8+EPyA6EkCZbvXYnpAAAAAElFTkSuQmCC);
      background-size: 16px 16px;
    }
  }
  :checked + .ui_checkbox:after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAZZJREFUWAnt2DFLxDAUAOBXz+t64ujSxUkc5MT/II7+Ch1cXFwUB5dbXMTBxR/iKk6CCgoqCNJuLi5Otlet9w5f9UKbpM3LkSGBI9f2Jfl4SRNoEMdxAQ6XGYdtY9osAaMoor9O1EmSjB3OZ9ADTdeLz6DPoGkGTNv7NShm8OIth53bFIpC74QtTxKxIxvXiNu8SiH9BshGv7PVEIIgkA41tSn+j0PR+WsO2zeZFIcPpwIUcaRamVMPr46g3lrWdbjTfghbi11lr1aBpjjUWwNy4BoB9x8yuH7/Uk4JBnDhtIG7dykMnoawfvmpRHLitICIO3nJMRY+hiBFcuO0gL3u5EZah7SB0wIeLodwsDS5HYhIWzgtIAbJkMfPWXl8YSwV3X2O4utq7bMYkViOHkcL8bdgJvfu/67pPhcO+2u0D1ZlklBUc+IaA7GBDMmNawWsQ9rAtQaKSFs4HEf7JcFgseB0byx0YG2+Iz5iu270klSNahOH4xkDq9Cc9zzQNJs+gz6DphkwbV9u1PRN2LRD7vbOvyQ//6y3go2ZKqgAAAAASUVORK5CYII=);
  }
}