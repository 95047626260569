@import "styles/constant.scss";

.noJumpNextPagination {
  :global(.ant-pagination-jump-prev) {
    pointer-events: none;
  }
  :global(.ant-pagination-jump-next) {
    pointer-events: none;
    & + * {
      display: none;
    }
  }
  :global {
    .ant-pagination-prev, .ant-pagination-next {
      &:not(:hover) {
        color: #393157;
        opacity: 0.8;
      }
      &:disabled {
        color: #807997;
        opacity: 0.6;
      }
    }

    .ant-pagination-item-active {
      border: 1px solid $cs-primary-color;
      margin-right: 8px !important;
    }

    .ant-pagination-prev {
      margin-right: 8px !important;
    }

    .ant-pagination-item {
      text-align: center;
      vertical-align: middle;
      list-style: none;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-color: #d9d9d9 !important;
      border-radius: 2px;
      margin-right: 8px !important;
    }
  }
}
