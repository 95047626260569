:global {
  .customkvTooltip {
    max-width: 300px !important;
  }

  .customkvTooltip .ant-tooltip-arrow {
    left: auto !important;
    right: 75px !important;
    position: absolute;
    transform: translateY(100%) translateX(-50%);
  }
}