@import "styles/constant.scss";

.wrapper {
  display: flex;

  $btnHeight: 18px;

  & .content {
    &::before {
      content: '';
      float: right;
      width: 0;
      height: calc(100% - #{$btnHeight});
    }
  }

  & .content .floatDiv {
    float: right;
    clear: both;
    font-size: 12px;
    height: $btnHeight;
    line-height: 1;
  }

  & .content .btn {
    transition: none;
    font-size: 12px;
    height: $btnHeight;
    line-height: 1;
    color: $cs-link-color;
  }
}




