@import "styles/constant.scss";

.notice {
  .navigator {
    display: flex;
    padding-left: 14px;
    margin: 0;
    cursor: pointer;
    li {
      margin-left: 10px;
      .navigatorChoose {
        padding: 10px 24px;
        background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
        border-radius: 8px;
        margin: 6px 0;
        font-size: 14px;
        color: var(--cs-primary-color-alt);
        width: auto;
        min-width: 64px;
        text-align: center;
      }
      .navigatorUnselected {
        padding: 10px 24px;
        border-radius: 8px;
        margin: 6px 0;
        font-size: 14px;
        color: #807997;
        width: auto;
        min-width: 64px;
        text-align: center;
      }
    }
  }
  .MarkasreadNormal:hover {
    svg {
      path {
        fill: $cs-primary-color;
      }
    }
  }
  .unread:hover {
    svg {
      path {
        fill: $cs-primary-color;
      }
    }
  }
  .Read:hover {
    svg {
      path {
        fill: $cs-primary-color;
      }
    }
  }
  :global(.ant-checkbox + span){
    padding-left: 4px;
    padding-right: 0px;
  }
  :global(.ant-badge-status-error){
    width: 8px;
    height: 8px;
  }
}
.noticeEcharts {
  .tab {
    display: flex;
    justify-content: flex-end;
  }
  :global(.CsNewTabs__tab___gEOBF ){
    font-size: 12px;
  }
}
