.primary_box {
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  background-color: rgba(var(--cs-primary-color-alt-rgb-params), 0.1);
  color: var(--cs-primary-color);
  border: 1px solid rgba(var(--cs-primary-color-alt-rgb-params), 0.2);
  padding: 0px 12px;
  height: 36px;
  border-radius: 18px;
}
.MinidumpSwitch{
  svg {
    path {
      fill: var(--cs-primary-color-alt);
    }
  }
}
