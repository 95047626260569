@import '~styles/mixins/variables.scss';
@import '~styles/mixins/global.scss';
@import '~styles/mixins/flexbox.scss';

.tag_item {
   height: 48px;
   clear:both;
   color:#4c4c4c;
   font-size: 0;
   line-height: 48px;
   border-bottom: 1px solid #efefef;
   @include display();
    > div {
      @extend %G-hide-overflow-text;
    }

   &.system_tag{
     .tag_do{
       display: none !important;
     }

     .tag_id{
       //padding-left: 22px;
     }

     .tag_id:before{
          position: absolute;
          content: "\7CFB";
          top: 50%;
          left: 0px;
          background-color: #73aef3;
          width: 18px;
          height: 18px;
          border-radius: 2px;
          line-height: 18px;
          color: #fff;
          font-size: 12px;
          margin-top: -9px;
          text-align: center;
          display: none;
     }
   }
   .tag_do{
       margin-left: 16px;
       text-align: center;
       display: none;
       a{
           display: none;
           cursor: pointer;
       }
   }
   .tag_undo {
     @include flex();
   }
   &:hover{
       background:#f8fcff;
       .tag_do{
             display: inline;

             &:last-child {
               margin-left: 12px;
             }
       }
       .tag_undo{
         display: block;
         margin-left: 16px;
         font-size: 13px;
         color: #888888;
       }
   }
   .tag_id,.tag_name,.tag_problem,.tag_do{
       font-size: 13px;
       //display: inline-block;
   }
   .tag_id{
       position: relative;
       width: 70px;
       margin-left: 30px;
   }
   .tag_name{
       width: 230px;
   }
   .tag_problem{
       width: 79px;
       text-align: left;
   }
}

.del_label{
  color: $global-font-color;
}

.del_tips{
  color: $global-font-color-warning;
  margin-top: 15px;
}

.modal_deltag{
     :global(.am-modal-btn){
       float: right;

      & + :global(.am-modal-btn) {
         margin-left: 0;
         margin-right: 12px;
         background-color: $btn-danger-bgcolor;
      }
  }
}

