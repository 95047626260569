$borderValue: 1px solid #e0e0e0;

.dataTable {
  .kvContainer {
    border-left: $borderValue;
    border-top: $borderValue;
  }

  .keyBox, .valueBox {
    display: flex;
    align-items: center;
    border-right: $borderValue;
    border-bottom: $borderValue;
    padding-left: 12px;
    min-height: 32px;
    overflow: auto;
    white-space: normal;
    word-break: break-all;
  }

  .keyBox {
    background: #f8f8f8;
  }
}
