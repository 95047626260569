@import "mixins/flexbox";
@import "mixins/utils";
@import "./font.scss";
@import "styles/constant.scss";

html body {
  font-family: $cs-font-main;
}

body {
  display: block !important;
  min-width: 1400px;
}

:global(.cs-antd-anchor) {
  color: $cs-primary-color;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #40a9ff;
  }

  &:active {
    color: #096dd9;
  }

  &:active, &:hover {
    text-decoration: none;
    outline: 0;
  }

  &:focus {
    text-decoration: none;
    outline: 0;
  }
}

:global(.hidden){
  display: none;
}

:global(.force_hide), :global(.hide_force){
  display: none !important;
}

:global(.visi_hide){
  visibility: hidden;
}

:global(.show_block){
  display: block !important;
}

:global(.clearfix) {
  @include clearfix();
}

:global(.fr) {
  float: right;
}

:global(.fl) {
  float: left;
}

:global(.strong) {
  font-size: 20px;
  color: #21292F;
  line-height: 25px;
}

:global(.color_blue){
    color:$cs-primary-color;
    font-size: 14px;
}
:global(.color_gray){
    color: #999;
}
:global(.color_red){
    color:#f44336;
}
:global(.color_yellow){
    color:#f0943c;
}


h3 a {
  color: #000;
}


:global(.dn) {
  display: none;
}

:global(.main-content) {
  //width: 580px;
  height: 100%;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
}

:global(.btn) {
  display: inline-block;
  border-radius: 2px;
  height: 40px;
  line-height: 41px;
  padding: 0 25px;
  cursor: pointer;
  min-width: 80px;
  //vertical-align: middle;
  &:local(.disabled) {
    background: #d0d0d0;
  }
}

:global(.btn_white){
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
}
:global(.btn_blue) {
  background: $cs-primary-color;
  color: #fff;
}
:global(.btn_red) {
  background: #f86054;
  color: #fff;
}

:global(.btn_gray) {
  background: #888;;
  color: #fff;
  cursor: default;
}

:local(.list_container){
  background: #FFFFFF;
  box-shadow: 1px 1px 10px #E9E9E9;
  border-radius: 4px;
  padding: 14px 0 120px;
  min-width: 960px;
}

:local(.title) {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #EBEBEB;
  padding: 0 30px;
  font-size: 14px;
}

:local(.issue_container) {
  composes: list_container;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 0;

  h3 {
    color: #454545;
  }

   &.unactive {
    position: relative;
    &:before {
      position: absolute;
      z-index: -1;
      content: '';
      opacity:0.5;
      background:#FFFFFF;
      box-shadow:0 4px 8px 0 rgba(0,0,0,0.10);
      border-radius:2px;
      height:60px;
      left:5px;
      right: 5px;
      bottom: -4px;
      transition: transform .5s ease-out;
    }
    &:after {
      position: absolute;
      z-index: -2;
      content: '';
      opacity:0.3;
      background:#FFFFFF;
      box-shadow:0 4px 8px 0 rgba(0,0,0,0.10);
      border-radius:2px;
      height:60px;
      left: 10px;
      right: 10px;
      bottom: -8px;
      transition: transform .5s ease-out;
    }


    .tab_container {
      .rollback:before {
        content: '展开';
      }
      .rollback[data-lang=en]:before {
        content: 'Expand';
      }
      .active:after {
        display: none;
      }
      ~ div {display: none;}
    }
    //收起时的悬浮动画
    &:hover {
      &:before {
        transform: translate(0,4px);
      }
      &:after {
        transform: translate(0,8px);
      }
    }
  }

  .subtitle {
    font-size:14px;
    color: $cs-main-text-color;
    text-align:left;
    margin: 15px 0;
  }

  .pager {
    position: relative;
    bottom: 60px;
  }
  .issueDetailsIcon {
    width: 22px;
    height: 22px;
    color: $cs-sub-text-color;
    :hover {
      color: $cs-primary-color-orange;
    }
  }
}

:local(.select_arrow){
    // border-color:  transparent transparent #5f5d5d #5f5d5d;
    // border-style: solid;
    // border-width: 0 0 2px 2px;
    // display: inline-block;
    // height: 8px;
    // width: 8px;
    // transform: translateY(-4px) rotate(-45deg);
  transform: translateY(-5px);
  width: 12px;
  height: 12px;
  background: url('../images/v2/newcs_dashboard_dateselect_icon.png') no-repeat
}

:local(.error_title){
    font-size: 14px;
    line-height: 17px;
}


:local(.select_box) {
  line-height: 1;

  :global(.Select) {
    display: inline-block;
    padding: 0 6px;
    width: 16%;
    color:#273238;
    vertical-align: top;
  }

  :global(.Select-control){
    height: 40px;
    border-radius: 2px;
    border-color: #e0e0e0;
    border-collapse: initial;
  }

  :global(.Select-placeholder) {
    color: #273238;
    line-height: 38px;
  }
  :global(.Select-clear-zone) {
    display: none;
  }
  :global(.Select-menu-outer) {
    border: none;
    background: transparent;
    box-shadow: none;
    padding-right: 12px;
  }
  :global(.Select-menu) {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    border: 1px solid #e0e0e0;
    background: #fff;
    width: 100%;
    border-radius: 4px;
  }
}

.operation:hover {
  //display: block;
  //visibility: visible;
  opacity: 1;
}

:local(.data_container) {
  width: 100%;
  padding: 0 30px;
  //margin-bottom: 20px;

  :global(.Select) {
    width: 20%;
    display: inline-block;
    font-size: 14px;
  }
}

:local(.issue_table) {
  width: 100%;
  font-size: 14px;
  table-layout: fixed;
  thead {
    tr {
      height: 20px;
      line-height: 20px;
      background: #fcfcfc;

    }
  }

  tbody {
    tr {
      height: 20px;
      line-height: 20px;
      cursor: pointer;
    }
  }
  tr {
    border-bottom: 1px solid #EBEBEB;
    &:global(.open) {
      border-bottom: none;
      border-top: 1px solid #EBEBEB;
    }
  }
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    white-space: nowrap;
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
    &:nth-child(n+4) {
      text-align: right;
    }
  }

  .select-box {
    margin-left: -6px;
    margin-right: -6px;
  }
}

:local(.is_report_record) {
    margin-bottom: 90px;

    thead tr {
      border-top: 1px solid #EBEBEB;
    }
    tbody tr {
      //background: #f8f8f8;
      .open_td {
        padding: 0;
      }
    }
    td {
      &:nth-child(n+4) {
        text-align: left;
      }
    }
}

:global(.icon-rectangle.down) {
  transform: rotate(180deg);
  display: inline-block;
  position: absolute;
  right: 12px;
  bottom: 10px;
  cursor: pointer;
  font-size: 24px;
}

:global(.icon-hourglass) {
  color: #ED7886;
}
:global(.icon-keyboard) {
  color: #EFBC1F;
}
:global(.icon-right) {
  color: #9BD560;
}
:global(.icon-tapd) {
  color: #0071CF;
  display: inline-block;
  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 20px;
    background: url(../images/Icon_TAPD.png);
    background-size: cover;
    vertical-align: -3px;
  }
}
:global(.select_icon) {
  &:before {
    margin-right: 10px;
  }
  line-height: 42px;
}
:global(.word_blue) {
  font-size: 14px;
  color: $cs-link-active-color;
  &:hover {
    color: $cs-link-hover-color;
  }
}

:local(.product_problem) {
  margin-top: 0;
}

:local(.product_problem_is_wetest_iframe) {
  margin-top: 60px;
}

:global(.inputMod){
  width:200px;
  height: 40px;
  // line-height: 40px;
  padding: 10px;
  border:1px solid #EBEBEB;
  display: inline-block;
  &:active,&:focus{
    outline: none;
    border: 1px solid $cs-primary-color;
  }
}

:global(.textareaMod){
  width:360px;
  padding: 10px;
  border:1px solid #EBEBEB;
  display: inline-block;
  height: 112px;
  resize: none;
  outline: none;
  &:active,&:focus{
    outline: none;
    border: 1px solid $cs-primary-color;
  }
}

:global(.status_processing) {
  cursor: pointer;
  display: inline-block;
  &:before {
    color: #efbc1f;
    margin-right: 10px;
  }
}

:global(.status_processed) {
  cursor: pointer;
  display: inline-block;
  &:before {
    color: #9bd560;
    margin-right: 10px;
  }
}

:global(.status_not_process) {
  cursor: pointer;
  display: inline-block;
  &:before {
    color: #ed7886;
    margin-right: 10px;
  }
}

:global(.status_processing_tapd) {
  &:before {
    transform: scale(0.8);
    transform-origin: bottom;
  }
}

:global(.status_change) {
  cursor: pointer;
  display: inline-block;
  color: #4C4C4C;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:before {
    font-size: 14px;
  }
  span {
    color:$cs-primary-color;
    display: none;
  }

  &:hover {
    span {
      display: block;
    }

  }
}

:global(.noborder) {
  border: none!important;
}

:global(.switch) {
  background:#FFFFFF;
  border:1px solid #E0E0E0;
  border-radius:2px;
  width:100px;
  height:32px;
  line-height: 32px;
  //vertical-align: middle;
  span {
    display: inline-block;
    height: 100%;
    width: 50%;
    vertical-align: top;
    &:first-child {
      border-right: 1px solid #EBEBEB;

      // margin-right: 20px;
    }
    font-size:12px;
    color:#273238;
    text-align:center;
    opacity: .89;
    cursor: pointer;
    &:global(.active) {
      opacity: 1;
      background: #f8f8f8;
    }
  }
}

:global(.fake_labels) {
  li {
    position: absolute;
    left: 0;
    font-size: 12px;
    height: 12px;
    color: #4c4c4c;
    text-align: left;
    top: 40px;
    text-indent: 15px;
    width: 100%;
    &:nth-child(2) {
      top: 83px;
    }
    &:nth-child(3) {
      top: 125px;
    }
    &:nth-child(4) {
      top: 168px;
    }
    &:nth-child(5) {
      top: 210px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-color: #EBEBEB;
    }

    :global(.bar_label) {
      color: #4C4C4C;
      position: absolute;
      top: -20px;
      left: -7px;
    }
    :global(.bar_value) {
      color: #888;
      position: absolute;
      top: -20px;
      right: 9px;
    }
  }
}

:global(.pie) {
  position: absolute;
  right: 24px;
  top: 50%;
  margin-top: -32px;
  width: 64px;
  line-height: 64px;
  border-radius: 50%;
  background: #f0f0f0 -webkit-linear-gradient(to right, transparent 50%, #E3A22C 0);
  color: transparent;
  text-align: center;
  &:global(.full) {
    background: #E3A22C;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0; left: 50%;
    width: 50%; height: 100%;
    border-radius: 0 100% 100% 0 / 50%;
    background-color: inherit;
    transform-origin: left;
    animation: spin 50s linear infinite, bg 100s step-end infinite;
    animation-play-state: paused;
    animation-delay: inherit;
  }
}

:global(.exception_page){
  height: 100%;
  width: 100%;
}

@keyframes spin {
  to { transform: rotate(.5turn); }
}
@keyframes bg {
  50% { background: #E3A22C; }
}

:global(.text_overflow) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:global(.overflow-hidden) {
  overflow: hidden;
}

:global(.timepicker) {
  position: absolute;
  left: 100%;
  top: 42px;
  z-index: 1;
  background: #fff;
  @extend %boxshadow;
}

:global(.left0) {
  left: 7px;
}

:global(.num_right) {
  text-align: right;
}

:global(.num_left) {
  text-align: left;
}

:global(.table_th){
  font-size: 14px;
}


//check
:local(.checkMod){
  position: relative;
  margin-left: 20px;
  width: 16px;
  height: 16px;
  label{
    cursor: pointer;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    background: #fff;
    border: 1px solid #EBEBEB;
    &:after{
      opacity: 0;
      content: '';
      position: absolute;
      width: 9px;
      height: 5px;
      background: transparent;
      top: 4px;
      left: 3px;
      border: 2px solid $cs-primary-color;
      border-top: none;
      border-right: none;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);

    }
  }
  input[type=checkbox]{
    visibility: hidden;
    &:checked+label{
      &:after{
        opacity: 1;
      }
    }
  }
  strong{
    font-weight:normal;
    display: inline-block;
    padding-left: 22px;
    vertical-align: middle;
  }
}



:local(.checkMod_pill){
  width: 36px;
  height:20px;
  position: absolute;
  background: #CCCCCC;
  border-radius: 50px;
  right:10px;
  :global(.background_fill){
    width: 100%;
    height: 100%;
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: 0;
  }
  :global(.on_background){
    background:$cs-primary-color;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    z-index: 1;
    -o-transition: .3s 0.2s;
    -ms-transition: .3s 0.2s;
    -moz-transition: .3s 0.2s;
    -webkit-transition: .3s 0.2s;
    transition: .3s 0.2s;
  }
  :global(.state_background){
    border-radius: 50px;
    z-index: 2;
    background: #CCCCCC;
    border: 1px solid #CCCCCC;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    -o-transition: .4s;
    -ms-transition: .4s;
    -moz-transition: .4s;
    -webkit-transition: .4s;
    transition: .4s;
  }
  :global(.handle_action){
    width: 16px;
    height:16px;
    background:#fff;
    top: 2px;
    left: 2px;
    position: absolute;
    border-radius: 16px;
    z-index: 3;
    -o-transition: -o-transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
    -ms-transition: -ms-transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
    -moz-transition: -moz-transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
    -webkit-transition: -webkit-transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
    -o-transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
    -ms-transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
    -moz-transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
    -webkit-transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
    transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  }
}
:local(.off) {
  :global(.handle_action){
    -o-animation: expand-off .3s 0.2s;
    -ms-animation: expand-off .3s 0.2s;
    -moz-animation: expand-off .3s 0.2s;
    -webkit-animation: expand-off .3s 0.2s;
    animation: expand-off .3s 0.2s;
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  :global(.on_background){
    -o-transition: .3s 0s;
    -ms-transition: .3s 0s;
    -moz-transition: .3s 0s;
    -webkit-transition: .3s 0s;
    transition: .3s 0s;
  }
  :global(.state_background){
    -o-transition: .4s 0.25s;
    -ms-transition: .4s 0.25s;
    -moz-transition: .4s 0.25s;
    -webkit-transition: .4s 0.25s;
    transition: .4s 0.25s;
  }
}

:local(.on) {
  :global(.handle_action){
    -o-animation: expand-on .3s 0.2s;
    -ms-animation: expand-on .3s 0.2s;
    -moz-animation: expand-on .3s 0.2s;
    -webkit-animation: expand-on .3s 0.2s;
    animation: expand-on .3s 0.2s;
    -o-transform: translate3d(16px, 0, 0);
    -ms-transform: translate3d(16px, 0, 0);
    -moz-transform: translate3d(16px, 0, 0);
    -webkit-transform: translate3d(16px, 0, 0);
    transform: translate3d(16px, 0, 0);
  }
  :global(.on_background){
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
  }
  :global(.state_background){
    -o-transform: scale(0);
    -ms-transform: scale(0);
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes expand-on {
  0% {
    width: 16px;
  }

  40% {
    width: 20px;
  }

  100% {
    width: 16px;
  }
}

@keyframes expand-off {
  0% {
    width: 16px;
    left: 2px;
  }

  40% {
    width: 23px;
    left: -7px;
  }

  100% {
    width: 16px;
    left: 2px;
  }
}

@-o-keyframes expand-on {
  0% {
    width: 16px;
  }

  40% {
    width: 20px;
  }

  100% {
    width: 16px;
  }
}

@-ms-keyframes expand-on {
  0% {
    width: 16px;
  }

  40% {
    width: 20px;
  }

  100% {
    width: 16px;
  }
}

@-moz-keyframes expand-on {
  0% {
    width: 16px;
  }

  40% {
    width: 20px;
  }

  100% {
    width: 16px;
  }
}

@-webkit-keyframes expand-on {
  0% {
    width: 16px;
  }

  40% {
    width: 20px;
  }

  100% {
    width: 16px;
  }
}

@-o-keyframes expand-off {
  0% {
    width: 16px;
    left: 2px;
  }

  40% {
    width: 23px;
    left: -7px;
  }

  100% {
    width: 16px;
    left: 2px;
  }
}

@-ms-keyframes expand-off {
  0% {
    width: 16px;
    left: 2px;
  }

  40% {
    width: 20px;
  }

  100% {
    width: 16px;
    left: 2px;
  }
}

@-moz-keyframes expand-off {
  0% {
    width: 16px;
    left: 2px;
  }

  40% {
    width: 23px;
    left: -7px;
  }

  100% {
    width: 16px;
    left: 2px;
  }
}

@-webkit-keyframes expand-off {
  0% {
    width: 16px;
    left: 2px;
  }

  40% {
    width: 23px;
    left: -7px;
  }

  100% {
    width: 16px;
    left: 2px;
  }
}


//修改amazeui 的modal弹窗头部样式
div:global(.am-modal-hd) {
  padding: 0 30px;
  font-size: 14px;
  text-align: left;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #EBEBEB;
  h4 {
    font-weight: normal;
  }
}

ul:global(.am-selected-list) li {
  padding: 5px;
  &:last-child {
    border-bottom: none;
  }
}

:global(.w960) {
  width: 960px;
  margin-left: auto;
  margin-right: auto;
}

:global(.rdr-Calendar) {
  width: 281px !important;
}

:global(.animated) {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  &:global(.infinite){
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}
:global(.bounce) {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
@keyframes bounce {
  50% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
  0%,100% {
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
  }
}
@-webkit-keyframes bounce {
  50% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
  0%,100% {
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
  }
}
@-moz-keyframes bounce {
  50% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
  0%,100% {
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
  }
}
@-ms-keyframes bounce {
  50% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
  0%,100% {
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
  }
}
@-o-keyframes bounce {
  50% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
  0%,100% {
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
  }
}

:global(.am-dropdown-content) {
  width: 100%;
}
:global(.am-selected-btn) {
  height: 40px;
}
:global(.am-selected) {
  padding: 0 6px;
}
:local(.question) {
  content: "";
  display: block;
  background: url(images/rule.png) no-repeat;
  background-size: cover;
  width: 13px;
  height: 13px;
  //display: inline-block;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  &:hover {
    .tips_pop {
      display: block;
    }
  }
}
:local(.why) {
  content: "";
  display: inline-block;
  background: url(images/rule.png) no-repeat;
  background-size: cover;
  width: 13px;
  height: 13px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 2px;
  margin-bottom: 4px;
  position: relative;
  &:hover{
    .why_tips, .tips_pop{
      display: block;
    }
  }
}
