.wrapper {
  width: 100px;
  height: 100px;
  border: 1px solid #E8E8E8;
  border-radius: 100%;
  margin: 0 auto;
  text-align: center;
  &:after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 1px;
    visibility: hidden;
    vertical-align: middle;
  }
  svg {
    vertical-align: middle;
  }
}