@import "styles/constant.scss";

.csCollapseButton {
  cursor: pointer;
  &.packupArrow {
    path {
      color: #807997;
    }
    &:hover {
      path {
        color: $cs-primary-color;
      }
    }
  }
  &.otherthreadDown {
    path {
      color: #807997;
    }
    &:hover {
      path {
        color: #6669FE;
      }
    }
  }
}

