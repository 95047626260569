.page_header {
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  margin-left: auto;
}
.select_box {
  composes: select_box from 'styles/common.scss';
  a {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    font-size: 13px;
    color: #4C4C4C;
    padding: 0 20px;
    line-height: 32px;
    display: inline-block;
    vertical-align: top;
    margin-right: 6px;
  }
  .searchBoxShell {
    display: inline-block;
  }
}
.uploadBtn{
  svg{
    path{
      fill:#fff;
    }
  }
}
