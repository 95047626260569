@import "../../../../styles/mixins/global.scss";
@import "styles/constant.scss";

.issue_table {
  composes: issue_table from 'styles/common.scss';
}

 .is_report_record {
   composes: is_report_record from 'styles/common.scss';
}

.check_allissue {
  display: block;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 13px;
  color: $cs-primary-color;;
  cursor: pointer;
}