@import "styles/constant.scss";

.unwindScanAlert {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  color: $cs-primary-color-orange;
}

.stackList {
  word-break: break-all;
  white-space: normal;
  font-size: 14px;
  margin: 0;
  width: 100%;
  border-top: 1px solid #ebebeb;

  .copyStackBtn {
    svg {
      path {
        fill: $cs-sub-text-color;
      }
    }
  }
  .copyStackBtn:hover {
    svg {
      path {
        fill: $cs-primary-color;
      }
    }
  }

  .stackLineContainer {
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
  }

  .stackLine {
    display: flex;
    padding: 5px 10px 5px 5px;
    background-color: #F7F7FF;
    font-family: Consolas, Menlo, Monaco, “DejaVu Sans Mono”, “Courier New”, monospace;
  }

  .stackHeader {
    display: flex;
    padding: 5px 10px 5px 5px;
    background-color: #f5f5f5;
    color: $cs-sub-text-color;
    font-weight: normal;
    user-select: none;
    font-size: 12px;
  }

  .stackMemoryExpand {
    color: #888;
  }

  .tooltip {
    width: max-content;
    display: flex;
    align-items: start;
    margin-top: 5px;
    justify-content: flex-start;
    padding-left: 3px;
  }

  .lineNumber {
    min-width: 36px;
    max-width: 36px;
    text-align: center;
    margin-right: 4px;
  }

  .jankStack {
    min-width: 360px;
    max-width: 360px;
    margin-right: 8px;
  }

  .libName {
    min-width: 200px;
    max-width: 200px;
    margin-right: 20px;
  }

  .stackLine .libName {
    font-weight: bold;
  }

  .unwindType {
    min-width: 80px;
    max-width: 80px;
    display: flex
  }

  .hex {
    min-width: 160px;
    max-width: 160px;
    margin-right: 8px;
  }

  .stackContent {
    flex-grow: 1;
  }
}
