// 主题色设置，以及相关css变量定义
:global {
  html body {
    --cs-primary-color: #FE8A40;
    --cs-primary-color-rgb-params: 254, 138, 64;
    --cs-primary-color-alt: #FA6710;
    --cs-primary-color-alt-rgb-params: 250, 103, 16;
    --cs-primary-color-hover: #FFB67B;
    --cs-primary-color-button-gradient-left: #E37E5E;
    --cs-primary-color-button-gradient-right: #FFB67B;
  }

  html body.cs-theme-blue {
    --cs-primary-color: #6669FE;
    --cs-primary-color-rgb-params: 102, 105, 254;
    --cs-primary-color-alt: #6669FE;
    --cs-primary-color-alt-rgb-params: 102, 105, 254;
    --cs-primary-color-hover: #9187EB;
    --cs-primary-color-button-gradient-left: #6669FE;
    --cs-primary-color-button-gradient-right: #9187EB;
  }
}

