@import "styles/constant.scss";

.crashsight {
  min-height: 100vh;
  background-color: #FCFCFC;
  font-family: $cs-font-main;
  color: #393157;
}

.watermark {
  background: transparent repeat;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  opacity: 0.5;
  pointer-events: none;
}

.scrollHeader {
  transition: transform .3s ease-out;
  transform: translate(0, 0);
  position: relative;
  z-index: 11;

  &.yes {
    transform: translate(0, -48px);
  }

  &.no{
    display: block;
  }
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  //padding-top: 112px;
  // z-index: 0;
  background: rgb(240, 242, 245);
  > div {
    height: 100%;
  }
}
.fix{
  padding-top: 52px;
}

.pop_box {
  :global(.am-modal-hd) + :global(.am-modal-bd) {
    padding-top: 15px;
  }
}

.itemWrap {
  font-size: 13px;
}

.messageWrap {
  margin-left: 26px;
  margin-right: 10px;
}

.notifyIcon {
  width: 16px;
  height: 16px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 10px;
  vertical-align: middle;
  position: absolute;
  left: 10px;
  top: 10px;
  &.success {
    background-image: url('images/notify_success.png');
  }
  &.info {
    background-image: url('images/notify_info.png');
  }
  &.error {
    background-image: url('images/notify_error.png');
  }
  &.warning {
    height: 13px;
    background-image: url('images/notify_warning.png');
  }
}

:global(.notification-item-wrap) {
  margin-top: 10px;
}

.subMenuContainer{
  width: $left-sub-menu-width;
  height: 100%;
  padding: 0px 16px 0 24px;
  border-right: 1px solid rgb(240, 233, 224);
  background: rgb(255, 255, 255);
  position: fixed;
  top: $cs-main-header-height;
  border-top: 1px solid #F0E9E0;
  overflow: auto;
  max-height: calc(100vh - 80px);
}
