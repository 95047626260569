@import "styles/constant.scss";
.config_page_box {
  composes: product_problem from 'styles/common.scss';
  padding: 20px 24px 0;
  .cardHead {
    display: flex;
    gap: 10px;
    margin: 0 25px;
    padding: 23px 0;
    font-weight: 500;
    font-size: 16px;
    color: #393157;
    line-height: 16px;
    border-bottom: 1px solid #E9E9E9;
    letter-spacing: -0.64px;
  }
}
.config_page{
  composes: list_container from 'styles/common.scss';
  min-height: calc(100vh - $cs-main-header-height - $cs-main-footer-height - 20px);
  padding: 0 0 10px;
}

.headerTip{
  font-size: 14px;
  margin-bottom: 10px;
}
.formatDescModal{
  min-height: 500px;
  :global{
    .ant-modal-body {
      padding: 16px 40px;
    }
  }
}