@import 'styles/constant.scss';

.crashSightLeftMenu {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: $left-menu-width;
  height: 100%;
  background: linear-gradient(0deg, #D3C7D9, #F1EAE1);
  display: flex;
  flex-direction: column;
  color: #FFF;
  font-size: 14px;
  transition: width 0.3s;

  overflow-y: auto;
  &.collapsed {
    width: $collapsed-left-menu-width;
  }
  a:not([disabled]):hover {
    text-decoration: none;
    color: var(--cs-primary-color-alt);
  }
}

.services {
  padding-bottom: 32px;
}

.logoContainer{
  display: flex;
  align-items: flex-start;
  padding-top: 32px;
  height: 90px;
  min-height: 90px;
}

.collapsedLogoContainer{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .iconBorder {
    width: 36px;
    height: 36px;
    background: rgba(254,138,64,0);
    border-radius: 10px;
    display: grid;
    place-items: center;
    margin-left: 15px;
  }

}

.divider {
  border: 1px solid rgba(128,121,151,0.15);
}

.footer {
  color: #B6BFD1;
  font-size: 16px;
  display: flex;
  margin: 0 0 32px 0;
  svg {
    cursor: pointer;
    path {
      fill: #807997;
    }
  }
}
.footer:hover {
  svg {
    path {
      fill: $cs-primary-color;
    }
  }
}

.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 8px;
  color: $cs-main-text-color;
  border-left: 4px solid transparent;
  :hover {
    text-decoration: none;
    color: var(--cs-primary-color-alt);
  }
  .menuItemWrapper {
    width: 212px;
    height: 48px;
    margin: 0px 12px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 400;
    border-radius: 8px;
    .menuItemIconWrapper {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      // border: 1px solid #D6CAC9;
    }
    .menuItemHorizontalArrowIcon {
      display: none;
    }
  }
  .menuItemWrapperHover {
    color: $cs-primary-color;
  }
  .collapsedMenuItemWrapper{
    width: 72px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 0px 8px 0px 4px;
  }

  &.menuItemSelected {
    border-left: 4px solid $cs-primary-color;
    .menuItemWrapper {
      background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
      font-weight: 700;
      color: var(--cs-primary-color-alt);
      .menuItemHorizontalArrowIcon {
        display: inline-block;
      }
    }
    .menuItemIconWrapper {
      border: none;
    }
    .collapsedMenuItemWrapper {
      background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
      font-weight: 700;
      color: var(--cs-primary-color-alt);
    }
  }
  &.parentMenuSelected {
    color: var(--cs-primary-color-alt);
    font-weight: 700;
    .menuItemIconWrapper {
      font-weight: 700;
      border: none;
      background-color: rgba(var(--cs-primary-color-rgb-params), 0.1);
    }
    .menuItemWrapper {
      font-weight: 700;
    }
    svg {
      path {
        fill: var(--cs-primary-color-alt);
      }
    }
  }


  &.menuItemDisabled {
    cursor: not-allowed;
    color: #777;

    svg {
      path {
        fill: #777;
      }
    }
    :hover {
      text-decoration: none;
      color: #777;
      svg {
        path {
          fill: #777;
        }
      }
    }
  }
  a {
    color: inherit;
  }

  svg {
    display: block;
    // width: 16px;
    // height: 16px;
    path {
      // fill: #B6BFD1;
    }
  }
}
.collapsed {
  .menuItem {
    .collapsedMenuItemWrapperHover {
      text-decoration: none;
      color: var(--cs-primary-color-alt);
      svg {
        path {
          fill: var(--cs-primary-color-alt);
        }
      }
    }
    &.parentMenuSelected {
      border-left: 4px solid $cs-primary-color;
      .menuItemIconWrapper {
        background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
        font-weight: 700;
        color: var(--cs-primary-color-alt);
      }
      .collapsedMenuItemWrapper {
        background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
        font-weight: 700;
        color: var(--cs-primary-color-alt);
        > div {
          &:last-child{
            width: 62px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.parentMenu {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  border-left: 4px solid transparent;
  .menuItemWrapper {
    margin-left: 16px;
  }
  .menuItemWrapperHover {
    color: var(--cs-primary-color-alt);
  }
}
.submenuItem {
  margin-bottom: 10px;
  display: block;
  width: $left-menu-width;
  border-left: 4px solid transparent;
  margin-left: -4px;
  &:last-child {
    margin-bottom: 0;
  }
  .subMenuItemWrapper {
    height: 40px;
    width: 212px;
    line-height: 40px;
    padding-left: 60px;
    font-weight: 400;
    border-radius: 8px;
    margin-left: 12px ;
    color: $cs-main-text-color;
  }
  :hover {
    color: var(--cs-primary-color-alt);
  }
}
.submenuItemSelected {
  border-left: 4px solid $cs-primary-color;
  .subMenuItemWrapper{
    background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
    color: var(--cs-primary-color-alt);
    font-weight: 700;
  }
}
.menuPopover {
  padding-left: 0px!important;
  .collapsedSubmenuItem {
    color: #333333;
    height: 32px;
    display: block;
    width: 120px;
    padding: 10px 8px;
    line-height: 1;
    &Select {
      color: var(--cs-primary-color-alt);
      display: block;
      width: 120px;
      font-weight: bold;
      border-radius: 8px;
      background: rgba($color: $cs-primary-color, $alpha: 0.1);
      > div {
        height: 100%;
      }
    }
    :hover {
      color: var(--cs-primary-color-alt);
    }
  }
  :global(.ant-popover-content) {
    margin-left: -3px;
  }
  :global(.ant-popover-arrow) {
    display: none;
  }
  :global(.ant-popover-inner) {
    width: 140px;
    box-shadow: 0px 1px 10px 0px #E9E9E9;
  }
  :global(.ant-popover-inner-content) {
    padding: 8px 10px;
  }
}
// 兼容屏幕高度比较低得时候，出现滚动条 OOM选项跟其他不对齐，子选项宽度超出,
@media screen and (max-height: 780px) {
  .parentMenu {
    .menuItemWrapper {
      margin-right: 6px;
    }
  }
  .submenuItem {
    width: 230px;
  }
}
