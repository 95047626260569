.charts_container {
  width: 100%;
  font-size: 0;

  > div {
    display: inline-block;
    width: 50%;
    height: 318px;
    vertical-align: top;
    padding: 20px 30px;
    &:first-child {
      padding-right: 20px;
    }
    &:last-child {
      padding-left: 20px;
    }

    .charts_title {
      height: 32px;
      line-height: 32px;
      font-size: 13px;
      color: #282828;
      text-align: left;

      .select_wrapper {
        float: right;
        :global(.am-selected-btn) {
          height: 31px;
        }
        :global(.btn) {
          height: 32px;
          line-height: inherit;
          margin-right: 0;
        }
      }
    }

    .chart_item {
      height: 100%;
      position: relative;
      :global(.fake_labels) {
        li {
          height: 13px;
          &:nth-child(1) {
            top: 45px;
          }
          &:nth-child(2) {
            top: 93px;
          }
          &:nth-child(3) {
            top: 140px;
          }
          &:nth-child(4) {
            top: 187px;
          }
          &:nth-child(5) {
            top: 235px;
          }
        }
      }
    }
  }
}

.rollback {
    line-height: 60px;
    margin-right: 30px;
    color: #888888;
    cursor: pointer;
    font-size: 13px;
    &:before {
      content: '收起';
    }
    &[data-lang=en]:before {
      content: 'Collapse';
    }
}

.downBtn{
  float: right;
  margin-top: 5px;
}
