@import "styles/constant.scss";

:global {
  a {
    color: $cs-link-color;
  }

  a:hover {
    color: $cs-link-hover-color;
  }

  a:not([disabled]):hover {
    text-decoration: underline;
  }
}

.ant-message {
  z-index: 10010;
}

// antd tooltip的背景色是深色，需要把超链接的颜色调淡
:global(.ant-tooltip) {
  a {
    color: #69c0ff;
    &:visited {
      color: #69c0ff;
    }
    &:hover {
      color: #bae7ff;
    }
  }
}

:global(.ant-btn-link) {
  color: $cs-link-color;
  &:hover {
    color: $cs-link-hover-color;
  }
}


// Button相关2.0样式
:global {
  .ant-btn {
    box-shadow: none !important;
    border-radius: 4px;
    transition: none;
    &:not(.ant-btn-icon-only) {
      padding-left: 12px;
      padding-right: 12px;
    }
    &:disabled {
      color: $cs-sub-text-color;
      background-color: #fff;
      opacity: 0.6;
    }
    &:not(.ant-btn-link):disabled {
      border-color: #C5C8DA;
    }
  }

  .ant-btn-default {
    color: $cs-sub-text-color;
    border-color: #C5C8DA;
  }

  .ant-btn-primary {
    &:hover {
      background: linear-gradient(to right, var(--cs-primary-color-button-gradient-left), var(--cs-primary-color-button-gradient-right));
    }
    &:disabled {
      color: #fff;
      background-color: #d6d6d6;
    }

    &.ant-btn-background-ghost {
      &:hover {
        color: var(--cs-primary-color-alt);
        border-color: var(--cs-primary-color-alt);
        background: rgba(var(--cs-primary-color-alt-rgb-params), 0.1);
      }
    }
  }

  .ant-btn-link {
    &:not([disabled]):hover {
      text-decoration: underline;
    }
  }
}


// Radio相关2.0样式
:global {
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: $cs-primary-color;
    }
    .ant-radio-inner::after {
      background-color: #FFF;
      transform: scale(0.37);
    }
  }
}


// Modal相关2.0样式
:global {
  .ant-modal-content {

  }
  .ant-modal-title {
    font-weight: bold;
  }
  .ant-modal-header {
    margin: 0 20px;
    padding: 20px 4px;
  }
  .ant-modal .ant-modal-close-icon {
    color: $cs-main-text-color;
  }
  .ant-modal-body {
    padding: 24px 40px;
  }
  .ant-modal-footer { // 设计规定，Model footer的buttons必须在中间
    margin: 0 20px;
    padding: 20px 4px;
    display: flex;
    justify-content: center;
    gap: 20px;

    .ant-btn.ant-btn.ant-btn {
      margin: 0;
    }
  }
}

// table 2.0的样式修改
:global {
  // table的表头去掉列之间的分隔符|
  .ant-table-thead > tr > th::before {
    display: none;
  }

  .ant-table-thead > tr > th {
    color: $cs-sub-text-color;
    padding: 4px 16px;
  }

  .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 4px 8px;
  }

  .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: $cs-sub-text-color;
    opacity: 1;
  }

  .ant-table-column-sorter-up, .ant-table-column-sorter-down {
    color: $cs-sub-text-color;
    opacity: 0.6;
  }

  .ant-table-cell {
    height: 32px;
    box-sizing: content-box;
  }
  // .ant-table-column-sorters {
  //   justify-content: unset;
  // }

  .ant-table-empty .ant-table-tbody > tr > td { // 表格数据为空的时候，不显示border-bottom
    border-bottom: none;
  }
}

// select 2.0的样式修改
:global {
  .ant-select::placeholder {
    color: $cs-sub-text-color;
    opacity: 0.6;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selector::after {
    line-height: 1;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 32px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 32px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 32px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    border-color: #C5C8DA;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--cs-primary-color-alt);
  }

  .ant-select-multiple .ant-select-selection-item-content, .ant-select-single .ant-select-selector .ant-select-selection-item {
    color: $cs-main-text-color;
  }

  .ant-select-item:not(.ant-select-item-option-disabled) {
    color: $cs-main-text-color;
  }

  .ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-left: 12px;
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    height: 28px;
    line-height: 28px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $cs-primary-color;
    font-weight: normal;
    background: transparent;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #FAFAFD;
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    left: 12px;
    right: 12px;
  }
  .ant-select-multiple .ant-select-selection-item {
    padding-inline-start: 10px;
    padding-inline-end: 8px;
    background: #f5f6f8;
  }
  .ant-select-arrow {
    right: 12px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px 12px;
  }
  .ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0px;
  }
  .anticon-close, .anticon-down, .anticon-search svg {
    color: $cs-main-text-color;
    opacity: 0.8;
  }
  .ant-select-item-group {
    color: $cs-sub-text-color;
  }
}

// picker 2.0的样式修改
:global {
  .ant-picker {
    border-radius: 4px;
    border-color: #C5C8DA;
    padding: 4px 12px;
  }

  .ant-picker:hover {
    border-color: var(--cs-primary-color-alt);
  }

  .ant-picker-input > input::placeholder {
    color: $cs-sub-text-color;
    opacity: 0.6;
  }
}

:global(.table_th),  :global(.ant-table-column-title), :global(.ant-table-thead > tr > th) {
  color: $cs-sub-text-color;
}

:global(.ant-table-thead > tr > th) {
  background: #f5f5f5;
}

:global(.ant-table-cell) {
  color: $cs-main-text-color;
}

:global(.ant-radio-wrapper > span) {
  color: $cs-main-text-color;
}

:global(.ant-select-selection-item) {
  color: $cs-main-text-color;
}
/* antd radio-group相关 */
:global {
  .ant-radio-button-wrapper {
    color: $cs-main-text-color;
  }
}
:global(.ant-radio-button-checked), :global(.ant-radio-button-wrapper-checked) {
  border-color: var(--cs-primary-color-alt) !important;
  color: var(--cs-primary-color-alt) !important;
  background: rgb(var(--cs-primary-color-alt-rgb-params), 0.05);
  &:hover {
    border-color: var(--cs-primary-color-alt) !important;
    color: var(--cs-primary-color-alt) !important;
  }
}
:global(.ant-radio-button-wrapper) {
  padding: 0 12px;
  box-shadow: none !important;
  &:hover {
    color: var(--cs-primary-color-alt) !important;
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}
/* antd radio-button-group相关 */
:global {
  .ant-radio-group-outline {
    .ant-radio-button-checked, .ant-radio-button-wrapper-checked {
      border-color: var(--cs-primary-color-alt) !important;
      color: var(--cs-primary-color-alt) !important;
      background: rgb(var(--cs-primary-color-alt-rgb-params), 0.05);

      &:hover {
        border-color: var(--cs-primary-color-alt) !important;
        color: var(--cs-primary-color-alt) !important;
      }
    }

    .ant-radio-button-wrapper {
      padding: 0 12px;
      box-shadow: none !important;

      &:hover {
        color: var(--cs-primary-color-alt) !important;
      }
    }
  }
}


// input 全局修改
:global {
  .ant-input::placeholder {
    color: $cs-sub-text-color;
    opacity: 0.6;
  }
  .ant-input, .ant-input-number {
    border-radius: 4px;
    border-color: #C5C8DA;
    font-size: 14px;
  }

  .ant-input:hover, .ant-input-number:hover {
    border-color: var(--cs-primary-color-alt);
  }
  .ant-input-number-input {
    height: 32px;
  }

  .ant-input-affix-wrapper {
    border-radius: 4px;
    border-color: #C5C8DA;
  }

  .ant-select-selection-placeholder {
    color: rgba(128, 121, 151, 0.6);
  }

  input::placeholder {
    color: rgba(128, 121, 151, 0.6);
  }
  .ant-picker-input > input {
    color: $cs-main-text-color;
  }

  input {
    color: $cs-main-text-color;
  }
  .ant-input-number-handler-wrap {
    opacity: 1;
  }
  .ant-input-number-handler-up-inner svg, .ant-input-number-handler-down-inner svg {
    color: $cs-main-text-color;
    opacity: 0.8;
  }
}


// form 全局修改
:global {
  .ant-form {
    color: $cs-main-text-color;
  }
  .ant-form-item-label > label {
    color: $cs-main-text-color;
  }
  .ant-form-item-label > label::after {
    content: '';
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
    display: none;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

// tooltip 全局修改
:global {
  .ant-tooltip-inner {
    background-color: #3B364F;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 12px;
  }
}

// treeSelect全局修改
:global {
  .ant-tree-select-dropdown {
    padding: 4px 0px;
  }
  .ant-select-tree .ant-select-tree-treenode {
    padding: 4px 0px 4px;
  }
  .ant-select-tree-treenode:hover {
    background: #FAFAFD;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background: transparent;
  }
  .ant-select-tree-title {
    color: $cs-main-text-color;
    font-weight: 300;
  }
  .ant-select-tree-treenode-checkbox-checked {
    .ant-select-tree-title {
      color: $cs-primary-color;
      font-weight: normal;
    }
  }
  .ant-select-tree-checkbox-inner {
    border-radius: 4px;
    border: 1px solid #807997;
  }
}


// Divider 样式修改
:global {
 .ant-divider-horizontal {
    border-width: 1px;
    border-top-color: rgba(128, 121, 151, 0.15);
  }
}
