@import "../../../styles/mixins/utils";

.tag {
  display: inline-block;
  border-radius: 2px;
  height: 18px;
  line-height: 16px;
  padding: 0 7px;
  font-size: 12px;
  margin-right: 6px;
  color: #63A1BD;
  background: #F7FCFF;
  border: 1px solid #D5E9F3;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  margin-top: 4px;
  &.uneditable {
    background: #F7FCFF;
    border: 1px solid #D5E9F3;
    color: #63A1BD;
  }

  &.deletable {
    background: rgba(56, 177, 106, 0.10);
    border: 1px solid rgba(56, 177, 106, 0.50);
    padding-right: 21px;
    position: relative;
    cursor: pointer;
    height: auto;
    max-width: 100%;
    word-wrap: break-word;
    a {
      max-width: 100%;
      color: #38B16A;
    }
    .icon_close {
      display: block;
    }
  }

  .icon_close {
    display: none;
    position: absolute;
    right: 7px;
    top: 50%;
    margin-top: -8px;
    cursor: pointer;
  }

  &.add_label {
    background: #fff;
    border: 1px solid #C5C8DA;
    color: #9e9e9e;
  }

  &.editable {
    background: #fff;
    color: #9e9e9e;
    border: 1px solid #C5C8DA;
    border-radius: 2px;
    min-width: 62px;
    cursor: pointer;
    input {
      display: none;
      border: none;
      outline: none;
      height: 100%;
      font-size: 12px;
    }
  }
  &.editing {
    color: #4c4c4c;
    border-style: solid;
    span {
      display: none;
    }
    input {
      display: block;
    }
  }

}
