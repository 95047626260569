@import '../../../styles/mixins/utils.scss';

.date_picker { 
   @include clearfix(); 
   font-size: 13px;
    color: #000;
    z-index: 3; 
      
   ul {
     list-style: none; 
   }
   
   > .item_picker{
     float: left;
     position: relative;
     
      &:first-child{
        input, ul {
          width: 70px !important;
        }
     }
     
     .input_box{
          position: relative;
         
          input {
            width: 56px;
            height: 40px;
            text-align: left;
            padding: 11px 0 11px 12px;
            font-size: 13px;
            box-sizing: border-box;
            border: 1px solid #E0E0E0;
            outline: none;
        
            + span {
              margin: 0 10px 0 8px;
            }
       }
     }
     
     ul {
       position: absolute;
       left: 0;
       top: 40px;
       right:0;
       width: 56px;
       display: none;
       overflow: auto;
       max-height: 150px;
       margin: 0;
       padding:0;
       border: 1px solid #e0e0e0;
       background-color: #fff;
        z-index: 3;
       
       > li{
           box-sizing: border-box;
           height: 36px;
           padding: 10px;
           border: 1px solid #ebebeb;
           border-bottom: none;
           cursor: pointer;
          
          &:first-child{
            border-top: none;
          }
          
          &:hover{
            background-color: #f8f8f8;
          }
       }
    
       &.open {
         display: block;
       }
     }
   }
}