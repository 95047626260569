@import "styles/constant.scss";

.crashSightHeader {
  height: $cs-main-header-height;
  min-width: 1200px;
  display: flex;
  align-items: center;
  padding: 0 24px 0 24px;
  @media (max-width: 1600px) {
    padding: 0 24px 0 24px;
  }
  background-color: #FFFFFF;
  color: #333333;

  :global(.ant-radio-button-wrapper:not(:first-child)::before) {
    display: none;
  }
  :global(.ant-radio-button-wrapper){
    border-left-width: 1.02px
  }

  &.notAppCreate {
    background-image: url('images/v2/newcs_topbar_dark_bg2560.png');
    color: #FFFFFF;
    padding-left: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.appCreate {
    background-color: transparent;
    color: #FFFFFF;
    padding-left: 0;
  }

  & > *:not(:last-child) {
    margin-right: 24px;
    @media (max-width: 1600px) {
      margin-right: 20px;
    }
  }

  a {
    color: inherit;
  }

  .appSelect {
    width: 260px;
    @media (max-width: 1600px) {
      width: 220px;
    }
    :global(.ant-select-selector){
      position: relative;
      left: -13px;
    }
    :global(.ant-select-selection-search) {
      left: 12px !important;
  }
    :global(.ant-select-selection-item){
      font-size: 14px;
      color: #393157;
      line-height: 30px;
      img {
        width: 24px;
        height: 24px;
        margin-top: 0px;
      }
    }
  }

  .docsIcon {
    cursor: pointer;
    color: $cs-main-text-color;
    &.docsIconNonAppView {
      color: #fff;
    }
    &:hover {
      color: $cs-primary-color;
    }
  }
}
:global(.ant-select-selection-item){
  font-size: 14px;
  color: #393157;
  line-height: 32px;
}
:global(.ant-radio-group-small .ant-radio-button-wrapper) {
  margin-right: 4px;
  background-color: #F5F5F5;
  border-radius: 4px;
}
:global(.ant-radio-button-wrapper-checked:not) {
  background: rgba(var(--cs-primary-color-rgb-params), 0.2);
  border-radius: 4px;
  border: 1px solid $cs-primary-color;
}
.myproject {
  width: 22px;
  height: 22px;
  svg {
    path {
      fill: #393157;
    }
  }
}
.darkBackground {
  width: 22px;
  height: 22px;
  svg {
    path {
      fill: #fff;
    }
  }
}
.myproject:hover {
  width: 22px;
  height: 22px;
  svg {
    path {
      fill: var(--cs-primary-color-alt);
    }
  }
}
.noticeIcon {
  width: 22px;
  height: 22px;
}

.noticeIcon:hover {
  width: 22px;
  height: 22px;
  svg {
    path {
      fill: var(--cs-primary-color-alt);
    }
  }
}

:global(.cs-global-header-dropdown){
  position: fixed !important;
}
.appManageUserDropdown{
  margin-left: 4px;
  font-size: 14px;
  color: #fff;
}
.userDropdown{
  margin-left: 4px;
  font-size: 14px;
  color: #393157;
}

.langDropdownMenu{
  width: 22px;
  height: 22px;
  border-radius: 3px;
  background-color: #fff;
  color: #393157;
  text-align: center;
  font-weight: bold;
}
.langDropdownContainer{
  display: flex;
  align-items: center;
  gap: 4px;
}

.userDropdownMenuAppView{
  margin-right: 40px;
}

.selectApp{
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  left: 0;
}

.platformLabel{
  display: flex;
  align-items: center;
  gap: 8px;
}

.platformIcon{
  display: flex;
  // align-items: center;
  justify-content: center;
  // color: #ACB5C6;
  width: 24px;
  height: 24px;
  padding-top: 2px;
}
.createProject{
  :global(.ant-checkbox-wrapper) {
    align-items: center;
  }
}
.WecomIconIsAppView{
  display: flex;
  align-items: center;
  svg{
    path{
      fill:#fff;
    }
  }
}

.userDropdownMenu{
  a:hover {
    text-decoration: none !important;
  }
}
