@import '../../../../styles/mixins/utils';

.popalert {
  &_mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 1000;
    overflow-y: scroll;
  }
  &_content {
    background:#ffffff;
    box-shadow:0 4px 8px 0 rgba(0,0,0,0.10);
    border-radius:2px;
    width:560px;
    min-height: 320px;
    margin: 10% auto 0;
    &.short {
      min-height: 326px;
    }
  }
  &_control {
    height: 60px;
    line-height: 60px;
    position: relative;
    padding-left: 30px;
    border-bottom: 1px solid #ebebeb;
    h1 {
      font-size:16px;
      font-weight:bold;
      color:#282828;
      text-align:left;
    }
  }
  &_closebtn {
    @include bg-image("./closebtn.png", 20px, 20px);
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  &_main {
    padding: 40px;
    min-height: 260px;
    position: relative;
    h2 {
      font-size:13px;
      color:#888888;
    }
  }
}

