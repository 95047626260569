@import "styles/constant.scss";

.dataHead {
  &.otherThread {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .fieldName {
    color: $cs-sub-text-color;
    flex-shrink: 0;
  }
  .fieldValue {
    color: $cs-link-color;
  }
  .fieldValueNoWrap {
    color: $cs-link-color;
    // text-overflow: 'ellipsis';
    // white-space: nowrap;
    white-space: normal;
    // overflow: hidden;
  }
  .fieldValueWrap {
    color: $cs-link-color;
    // text-overflow: 'ellipsis';
    // white-space: nowrap;
    white-space: pre-wrap;
    // overflow: hidden;
  }
}
.missingBuild {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  color: $cs-primary-color-orange;
}
