@import "styles/constant.scss";

.product_problem {
  composes: product_problem from 'styles/common.scss';
}
.product_problem_list{
  composes: list_container from 'styles/common.scss';

  padding: 16px 32px 32px 32px;
  padding-top: 0px;

  position: relative;
  // overflow: hidden;
  font-size:13px;
  color:#273238;

  .issue_list {
    position: relative;
    min-height: 600px;

    .empty_tip {
      position: absolute;
      left: 50%;
      top: 160px;
      width: 80px;
      margin-left: -40px;
      color: #807997;
      text-align: center;
      font-size: 14px;
    }
  }
}

.checkbox_container {
  width: 42px;
  height: 100%;
  text-align: center;
  float: left;
  position: absolute;
  left: 0;
  top: 0;
}

.others_msg {
  min-width: 110px;
  height: 100%;
  font-size: $cs_text_font_middle;
  float: right;
  text-align: center;
}

.last_report {
  height: 100%;
  font-size: $cs_text_font_middle;
  min-width: 110px;
  text-align: center;
}

.error_man {
  // position: absolute;
  bottom: 28px;
  right: 40px;
  // transform: translateY(50%);
  font-size: 12px;
  color: #888888;
  line-height: 14px;
  text-align: right;
  cursor: pointer;
  span:first-child {
    margin-right: 5px;
  }
  .status_processing {
    color: #9bd560;
  }
  .status_wait {
    color: #ed7886;
  }
}

