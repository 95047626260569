.myWallet {
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    padding-bottom: 19px;
    font-weight: bold;
    border-bottom: 1px solid #F9F8FA;
  }

  .balanceNumber {
    font-weight: bold;
    font-size: 18px;
  }
}
