@import "styles/constant.scss";

.topIssueList {
  :global(table) {
    max-width: none; // amazeui那边有个垃圾全局样式max-width: 100%，会导致antd的滚动有问题
  }
  margin-top: 8px;
}

.hidden_none{
  overflow: inherit;
}
.problem_box{
  position: relative;
}
.problem_name{
  position: relative;
  a{
    color: $cs-link-active-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.problem_list{
  font-size: 13px;
  line-height: 22px;
  clear: both;
  overflow: hidden;
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0;
  }
  .problem_itemName{
    color: #fff;
    opacity: .6;
    float: left;
    width: 66px;
  }
  .problem_itemContent{
    color: #fff;
    float: left;
    min-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

.processor_list {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
  word-break: break-word;
}

.custom_table {
  padding-bottom: 40px;
  :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
    background-color: #fafafd;
  }
  :global( .ant-table-tbody > tr > td.ant-table-cell-row-hover) {
    background-color: #fafafd;
  }
}



.error_title_id {
  max-width: 72px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.error_title_type {
  max-width: 176px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.error_title{
  composes: error_title from 'styles/common.scss';
  font-size:14px;
  line-height: 24px;
  font-weight: 500;
  word-break: break-all;
  width: 100%;
  white-space: normal;
}

.link_item {
  &:hover {
    text-decoration: none!important;
  }
}