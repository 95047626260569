@import "../../../../styles/mixins/utils.scss";
@import "../../../../styles/mixins/global.scss";
@import "styles/constant.scss";

.listItemContainer {
  border-bottom: 1px solid #EBEBEB;
  &:hover {
    background: #F8FCFF;
  }
  padding: 10px 0;
}

.quickPreview {
  padding: 8px 30px 16px 30px;
}

.list_item {
  padding: 0px 0px 0px 42px;
  margin: 0;
  position: relative;
  &:hover {
    .status_btn{
      display: block;
    }
    .hover_text {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  &:not(:hover) {
    .displayOnHover {
      display: none;
    }
    .hover_text {
      display: none;
    }
  }
  &.disabled {
    background: #fafafa;
    color:#CCCCCC;

    .error_info, .time {
      color:#CCCCCC;
    }

    .doneMsg{
      color: #888888;
    }

    .gray{
      color:#CCCCCC;
    }

    .error_title {
      color:#6E9FC8;
      font-size: 14px;
      text-decoration: line-through;
    }
    .error_info, .key_stack {
      text-decoration: line-through;
    }
  }
  :global(.status_processing){
    font-size: 12px;
    &:before{
      margin-right: 0;
    }
  }
  :global(.status_not_process){
    font-size: 12px;
    &:before{
      margin-right: 0;
    }
  }
  :global(.status_processed){
    font-size: 12px;
    &:before{
      margin-right: 0;
    }
  }

}

.error_title_id {
  max-width: 72px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (min-width: 2560px) {
    max-width: unset;
  }
}

.error_title_type {
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 1600px) {
    max-width: 72px;
  }
  @media (min-width: 2560px) {
    max-width: unset;
  }
}

.error_title{
  composes: error_title from 'styles/common.scss';
  font-size:14px;
  line-height: 24px;
  font-weight: 500;
  word-break: break-all;
  width: 100%;
  white-space: normal;
}

.error_info {
  margin-top: 0;
  font-size: 13px;
  color: #4C4C4C;
  line-height: 20px;
  overflow: hidden;

  &.true {
    display: none;
  }

  .less_info {
    max-height: 60px;
    cursor: pointer;
  }

  .key_stack {
    display: inline-block;
    margin: 0;
    white-space: normal;
    max-width: 90%;
    vertical-align: top;
    @extend %G-hide-overflow-text;
  }

  .crash_detail {
    background: #FDFDFD;
    border: 1px solid #EBEBEB;
    font-size: 12px;
    color: #4C4C4C;
    padding: 12px 16px;
    margin: 4px 0 7px;
    line-height: 20px;
    ol > li {
      list-style: decimal inside none;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btns {
      overflow: hidden;
      color: $cs-primary-color;

      a {
        color: $cs-primary-color;
      }
    }
}

> div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
}

.error_detail_fake {
  visibility: hidden;
  opacity: 0;
  margin: 6px 0 0;
 }

.error_detail {
  padding: 12px 16px 32px;
  background: #FDFDFD;
  border: 1px solid #EBEBEB;
  position: absolute;
  left: 70px;
  right: 40px;
  top: 65px;
  p {
    font-family: Consolas, Menlo, Monaco, "“DejaVu Sans Mono”", "“Courier New”", monospace;
    font-size: 12px;
    color: #4c4c4c;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .error_detail_indexing {
      width: 20px;
    }
    .error_detail_name {
      width: 150px;
    }
  }
  .line_table{
    display: table;
  }
  .line_info{
    display: table-row;
  }
  .highLight{
    font-weight: bold;
    color: $cs-link-color;
  }
  .index{
    padding-right: 5px;
    width: 20px;
    text-align: left;
    display: table-cell;
    vertical-align: middle;
  }
  .libName{
    padding-right: 10px;
    display: table-cell;
    vertical-align: middle;
  }
  .addr{
    white-space: normal;
    vertical-align: middle;
  }
  .line_no{
    padding-right: 10px;
    display: table-cell;
  }
  .line_msg{
    display: table-cell;
    white-space: normal;
    word-break: break-all;
    @extend %G-hide-overflow-text;
  }
}

.btn_word {
  font-size: 12px;
  color: $cs-primary-color;
  line-height: 20px;
  cursor: pointer;
}

.check_all {
  position: absolute;
  left: 16px;
  bottom: 10px;
}

.roll_back {
  position: absolute;
  right: 16px;
  bottom: 10px;
}

.status_btn{
  display: none;
  cursor: pointer;
  color: $cs-primary-color;
  font-size: 14px;
}

.external_mod_checkbox {
  top: 50%;
  transform: translateY(-50%);
}

.quick_pre_btn {
  background: $cs-primary-color;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 12px;
  padding: 2px 12px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to right, var(--cs-primary-color-button-gradient-left), var(--cs-primary-color-button-gradient-right));
    text-decoration: none !important;
    color: #FFFFFF !important;
  }
}

.status_name_show {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
  word-break: break-all;
  cursor: pointer;
}

.status_tooltip {
  padding-top: 0px !important;
  :global {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        display: none;
      }
    }
  }
}
