.mail_content{
  p{
    line-height:26px;
    color: #4C4C4C;
    font-size: 14px;
  }
  :global(.btn) {
    margin: 25px auto;
    width:320px;
  }
  :global(.inputMod){
    width: 320px;
    height: 48px;
    // line-height:48px;
    font-size: 20px;
    color: #282828;
    text-align: center;
  }
}

.error_info{
  color: #f44336;
  height: 40px;
  line-height: 40px;
  margin-top: 20px;
}

.error{
  :global(.inputMod){
    border: 1px solid #dc5975;
  }
}

.save_succ{
  color: #6cb132;
  margin-left: 10px;
  display: block;
}