@import '~styles/mixins/global.scss';
.select_container{
  width: 100%;
  //height: 42px;
  position: relative;
 //  z-index: 1;
}


.select_control{
    width: 100%;
    height: 32px;
    line-height: 32px;
    border: 1px solid #e0e0e0;
    padding: 0 12px;
    position: relative;
    @extend %G-hide-overflow-text;
    border-radius: 4px;
    border-color: #C5C8DA;
}

.select_control_name{
      font-size:12px;
      color:#808080;
      position: absolute;
      left: 12px;
      top: 12px;
      line-height: 1;
}

.select_options{
    width: 100%;
    position: absolute;
    left: 0;
    top: 33px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    // border-top: none;
    z-index: 10;
    padding: 4px 0px;

    ul li {
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
      // border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      &:hover {
        background: #FAFAFD;;
      }
      &:global(.active) {
        color: var(--cs-primary-color-alt);
      }
      &:last-child {
        border-bottom: none;
      }
    }
}

.select_type {
      height: 34px;
      line-height: 34px;
      padding: 0 10px;
      border-bottom: 1px solid #e0e0e0;
      font-size: 12px;
      color: #888;
}

.select_arrow{
  composes: select_arrow from 'styles/common.scss';
  margin-top: 14px;
  vertical-align: middle;
  float: right;
}

