$cs-theme-color-orange-primary-color: #FE8A40;
$cs-theme-color-blue-primary-color: #6669FE;

$cs-primary-color: #{"var(--cs-primary-color)"};
$cs-primary-color-alt: #{"var(--cs-primary-color-alt)"};
$cs-primary-color-hover: #{"var(--cs-primary-color-hover)"};
$cs-link-color: #6669FE;
$cs-link-hover-color: #A452DD;
$cs-link-active-color: #6669FE;
$cs-main-text-color: #393157;
$cs-sub-text-color: #807997;

$cs-primary-color-red: #F44336;
$cs-primary-color-orange: #FA6710;
$cs-warning-color: #FA6710;

$cs-font-welcome-gothic-bold: GOTHICB, "Microsoft YaHei", FounderLantingBlack;
$cs-font-welcome-gothic: GOTHIC, "Microsoft YaHei", FounderLantingBlack;
$cs-font-welcome-gilroy: GilroyBold, "Microsoft YaHei", FounderLantingBlack;

// font-family除了2.0版本设计加入的Helvetica，其他字体名单来自antd 4.x的字体说明：https://4x.ant.design/docs/spec/font-cn
$cs-font-main: HelveticaProRoman, "Microsoft YaHei", FounderLantingBlack, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$cs-font-light: HelveticaProLight, "Microsoft YaHei", FounderLantingFineBlack, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$cs_text_font_large: 16px;
$cs_text_font_middle: 14px;
$cs_text_font_small: 12px;

$left-menu-width: 224px;
$collapsed-left-menu-width: 88px;
$left-sub-menu-width: 240px;

$cs-main-header-height: 72px;
$cs-main-footer-height: 48px;
$cs-main-global-notification-height: 32px;
:export {
  themeColorOrangePrimaryColor: $cs-theme-color-orange-primary-color;
  themeColorBluePrimaryColor: $cs-theme-color-blue-primary-color;
  primaryColor: $cs-primary-color;
  primaryColorRed: $cs-primary-color-red;
  primaryColorOrange: $cs-primary-color-orange;
  linkColor: $cs-link-color;
  linkHoverColor: $cs-link-hover-color;
  linkActiveColor: $cs-link-active-color;
  mainTextColor: $cs-main-text-color;
  subTextColor: $cs-sub-text-color;
  textFontLarge: $cs_text_font_large;
  textFontMiddle: $cs_text_font_middle;
  textFontSmall: $cs_text_font_small;
  fontMain: $cs-font-main;
  fontLight: $cs-font-light;
  mainHeaderHeight: $cs-main-header-height;
  leftMenuWidth: $left-menu-width;
  collapsedLeftMenuWidth: $collapsed-left-menu-width;
  leftSubMenuWidth: $left-sub-menu-width;
  isGlobalNotification: $cs-main-global-notification-height;
}
