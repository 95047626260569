@import "styles/constant.scss";
.errorTitle {
  font-size: 14px;
  color: $cs-main-text-color;
  margin-bottom: 14px;
}

.errorTips {
  font-size: 14px;
  color: $cs-sub-text-color;
  margin-bottom: 21px;
}

.guideLink {
  color: var(--cs-primary-color-alt);

  &:hover {
    color: var(--cs-primary-color-alt);
  }
}
