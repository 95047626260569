@import "styles/constant.scss";
.copyright {
  font-size:12px;
  // color: $cs-main-text-color;
  color: #807997;
  line-height: 48px;
  text-align:center;
  p {
    margin-bottom: 0;
  }
}