@import 'styles/constant.scss';

.menuItemExpandSvg {
  height: 12px;
  width: 12px;
  position: absolute;
  right: 24px;
  svg {
    height: 12px;
    width: 12px;
  }
}