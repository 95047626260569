@import "../../../styles/mixins/flexbox.scss";
@import "styles/constant.scss";

.product_problem {
  composes: product_problem from 'styles/common.scss';
  padding: 0 24px 0.5px;
}

.product_summary {
  composes: issue_container from 'styles/common.scss';
  font-size: 14px;
  // min-height: 300px;
  padding: 0px 24px;
}

.title {
  composes: title from 'styles/common.scss';
  position: relative;
  .select_box {
    display: inline-block;
    width: 80%;
    vertical-align: top;
    margin-top: 8px;
    z-index: 1;
    .select_mod{
      display: inline-block;
      vertical-align: top;
      width: 22%;
      :global(.Select){
        width: 100%;
      }
      :global(.am-dropdown) :global(.am-selected-btn) {
        border-color: #fff;
      }
      :global(.am-dropdown) {
        padding: 0;
        margin-left: 6px;
      }
    }
    :global(.Select) {
      width: 18%;
      &:last-child {
        :global(.Select-arrow) {
          vertical-align: 3px;
        }
      }
    }
    label {
      font-size: 13px;
      color: #888;
      line-height: 43px;
      margin-right: -6px;
    }
  }
  :global(.Select-control) {
    border: none;
    box-shadow: none!important;
    margin-top: 2px;
  }
  .select_control {
    border: none;
  }
  .top_issue_select_box{
    padding-left: 30px;
    display: inline-block;
    width: 60%;
    vertical-align: top;
    z-index: 1;
  }
}

.select_box {
  composes: select_box from 'styles/common.scss';
  :global(.Select) {
    width: 23%;
    font-size: 14px;
  }
  :global(.am-dropdown) {
    vertical-align: top;
    width: 23%;
  }
}


.tab_container {
  border: none;
  height: auto;
  margin-left: auto;
  float: right;
}

.tab_item {
  margin-left: 0;
  border: none;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  &:last-child {
    margin-right: 10px;
  }
  &:global(.active) {
    &:after {
      height: 2px;
    }
  }
}

.vs {
  display: inline-block;
  width: 8%;
  height: 42px;
  line-height: 42px;
  font-size: 13px;
  color: #91a3ad;
  text-align: center;
}

.charts_container {
  font-size: 14px;
  position: relative;
  padding-bottom: 24px;
}

.bangdan {
  width: 100%;
  padding: 0px 4px;
  @include display();
}

.bangdan_item {
  @include flex(1);
  width: 1%;
  border-right: 1px solid #EBEBEB;
  padding: 0px 20px;
  margin-bottom: 20px;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    border-right: none;
  }
  :global(.switch) {
    float: right;
    width: 160px;
    border: none;
    span {
      opacity: 1;
      padding: 0 10px;
      color: #282828;
      &:first-child {
        border-right: none;
      }
      &:global(.active) {
        position: relative;
        background: transparent;
        &:after {
          content: '';
          position: absolute;
          left: 10%;
          bottom: 0;
          width: 80%;
          height: 2px;
          background-color: $cs-primary-color;
        }
      }
    }
  }
}

.bangdan_title {
  font-size: 14px;
  color: #393157;
  font-weight: bold;
  text-align: left;
  display: inline-block;
  line-height: 14px;
  height: 14px;
}

.list_title {
  font-size: 13px;
  color: #282828;
  text-align: left;
  line-height: 50px;
  padding-left: 30px;
}

// wenqing: 之前这里有人写了个很恶心的全局table样式，导致各个地方的table样式都被污染了，现在改成class名
.very_dirty_table_hack {
  font-size: 13px;
  color: #273238;
  text-align: left;
  width: 100%;
  border-top: 1px solid #EBEBEB;
  table-layout: fixed;
  thead tr {
    background: #fcfcfc;
    height: 42px;
    td {
      vertical-align: middle;
      white-space: pre;
    }
  }
  tbody tr {
    height: 48px;
    line-height: 48px;
    td {
      vertical-align: baseline;
    }
  }
  tr {
    border-bottom: 1px solid #EBEBEB;
    &.done {
      background: #fafafa;
      :global(.word_blue) {
        color: #6E9FC8;
        text-decoration: line-through;
      }
      td, :global(.status_change) {
        color: #ccc;
      }
    }
  }
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
    // &.increase:after {
    //   content: '';
    //   width: 7px;
    //   height: 10px;
    //   background: url("./increase.svg") no-repeat 0 0;
    // }
    // &.decrease:after {
    //   content: '';
    //   width: 7px;
    //   height: 10px;
    //   background: url("./increase.svg") no-repeat 0 0;
    // }
  }
}

.increase {
  //width: 7px;
  //height: 10px;
  // background: url("./decrease.svg");
  display: inline-block;
  fill: #F08000;
}

.bar_container {
  position: relative;
  :global(.fake_labels) {
    li:after {
      display: none;
    }
  }
}

.retract {
  text-align: center;
  width: 150px;
  height: 48px;
  margin: 0 auto;
  color: $cs-primary-color;
  line-height: 48px;
  cursor: pointer;
}

.setting_container {
  display: none;
  width: 147px;
  z-index: 1;
  position: absolute;
  box-shadow: 0 -2px 2px rgba(1, 1, 1, .1);
  height: 160px;
  top: 19px;
  left: 44px;
  color: black;
  &:hover {
    display: block;
  }
}

.item{
  &:hover :global(.status_change) {
    span {
      display: block;
    }
  }
}

.summary_data_type {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  font-weight: normal;
  color: #000;
  line-height: 22px;
  &:hover .setting_container {
    display: block;
  }
}

.page_title {
  min-width: 960px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.summaryTabArea {
  margin-bottom: 24px;
}

.summary_type_menu {
  overflow: hidden;

  display: block;
  background: #fefffe;
  width: 200px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 7px rgba(163, 173, 180, 0.36);
  -moz-box-shadow: 0 0 7px rgba(163, 173, 180, 0.36);
  box-shadow: 0 0 7px rgba(163, 173, 180, 0.36);
  border: 1px solid #e0e0e0;
  li {
    display: block;
    //margin: 14px 0px;
    height: 34px;
    line-height: 34px;
    span {
      margin-left: 14px;
    }
    &:hover {
      background: #f7fbfe;
      cursor: pointer;
    }
  }
  &:global(.active) {
    display: block;
  }
}

.increase {
  font-size: 12px;
  // color: #f96969;
  vertical-align: -1px;
  // transform: rotate(180deg);
  // -ms-transform: rotate(180deg); /* IE 9 */
  // -moz-transform: rotate(180deg); /* Firefox */
  // -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
  // -o-transform: rotate(180deg);
  // position: absolute;
  // top: 45%;
  // right: 0;
  //margin-right: 3px;
  margin-top: -6px;
  //display: inline-block;
}

.decrease {
  font-size: 12px;
  color: #4cb989;
  margin-top: 2px;
  // position: absolute;
  // top: 50%;
  // right: 0;
  // margin-top: -6px;
  //margin-right: 3px;
  //display: inline-block;
}

.downArrow {
  font-size: 12px;
  display: inline-block;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
  -o-transform: rotate(180deg);
}

.realtime_tip {
  font-size: 12px;
  color: #429289;
  background: rgba(193, 226, 202, 0.10);
  border: 1px solid rgba(66,146,137,0.2);
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: none;
  padding: 0 12px;
  z-index: 11;
  border-radius: 2px;
}

.no_realtime_data_tip {
  font-size: 12px;
  line-height: 2;
  color: #E2BA7F;
  background: #FEF9F3;
  border: 1px solid #F8E8D6;
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
  width: 305px;
  text-align: center;
  margin: 0 auto;
}

.data_type_box{
  padding-left: 8px;
  display: flex;
  color: $cs-sub-text-color;
  :global(.radioMod){
    label{
      vertical-align: 4px;
    }
    strong{
      vertical-align: 8px;
    }
  }
}

.data_type{
  padding-left: 0px;
  align-items: center;
  font-size: 14px;
  color: $cs-sub-text-color;
  font-weight: normal;
  padding-right: 10px;
}

.topIssue_box{
  padding-top: 15px;
}

.headerContainer {
  background-color: #fff;
  margin-left: -24px;
  margin-right: -24px;
  padding: 20px 24px 0px;
  box-shadow: 0px 1px 10px 0px #E9E9E9;
}


.updateTimeTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #C5C8DA;
  border-radius: 4px;
  height: 20px;
  cursor: default;
  &Text {
    font-size: 12px;
    line-height: 12px;
    padding-left: 4px;
    padding-right: 6px;
    color: #807997;
  }
  &Icon{
    box-sizing: content-box;
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.subscribeBtn {
  display: inline-block;
  cursor: pointer;

  &:hover {
    svg {
      g g {
        stroke: var(--cs-primary-color);
      }
      path {
        fill: var(--cs-primary-color);
      }
    }
  }
}

.compareBtn {
  position: absolute;
  top: -12px;
  right: 0px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.compareBtn:hover {
  svg {
    path {
      fill: var(--cs-primary-color-alt);
    }
  }
}


.updateTimeTagImg {
  margin-top: 6px;
  svg {
    width: 14px;
    height: 12px;
    margin: 3px;
  }
}


.error_title_id {
  max-width: 72px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.error_title_type {
  max-width: 176px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
