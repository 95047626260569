.filterContainer {
  width: 100%;
  // border: 1px solid #d9d9d9;
  background-color: #FAFAFD;
  border-radius: 2px;
  padding: 8px;
  :global(.ant-input-number){
    height: 32px;
  }
}

.singleFilterBox {
  // border: 1px solid #d9d9d9;
  position: relative;
  background-color: #FAFAFD;
  display: flex;
  flex-direction: row;
  align-items: start;
}

.singleFilterFieldSelect {
  position: relative;
  width: 180px;
  flex-shrink: 0;

  &:hover .singleFilterFieldSelectDeleteBtn {
    display: block;
    z-index: 1000;
  }
}

.singleFilterFieldSelectDeleteBtn {
  display: none;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 180px;
  transform: translate(-50%, -50%) ;
  width: 16px;
  height: 16px;
  background-color: #fff;
  svg {
    path {
      fill: #807997;
    }
  }

  &:hover {
    display: block;
    background-color: #fff;
    svg {
      path {
        fill: #393157;
      }
    }
  }
}

:global(.issueCrashFilterDeprecatedTimePickerHack) {
  height: 32px;
  line-height: 32px;
  background-color: #FFF;

  span[class*="select_arrow"] {
    margin-top: 14px;
  }
}
