@import "styles/constant.scss";

.tab {
  display: flex;
  background-color: rgba(245, 245, 245, 0.8);
  border-radius: 4px;
  padding: 4px;
  gap: 2px;
  .tab_item, .tab_item_checked {
    padding: 5px 10px;
    line-height: 14px;
    cursor: pointer;
    border-radius: 4px;
  }
  .tab_item {
    color: $cs-main-text-color;
    :hover {
      color: var(--cs-primary-color-alt);
    }
  }
  .tab_item_checked {
    background-color: $cs-primary-color;
    color: #fff;
  }
}
