@import "styles/constant.scss";

.tips {
  background: #FEF9F3;
  border: 1px solid #F8E8D6;
  margin-bottom: 10px;
  p {
    font-size: 13px;
    color: #BF8530;
    line-height: 30px;
    padding-left: 12px;
    margin: 0;
  }
}

.upload_area {
  // border: 2px dashed #ebebeb;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  text-align: center;
  &:hover {
    // background: #fdfdfd;
  }
  svg {
    margin-top: 0px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #4C4C4C;
    margin: 16px 0;
  }
  .sub1 {
    font-size: 14px;
    color: #4b4b4b;
    margin-top: 12px;
  }
  .sub2 {
    font-size: 12px;
    color: #91A3AD;
    margin-top: 12px;
  }
  a {
    cursor: pointer;
    color: $cs-primary-color;
  }
}
.tryAgainButton{
  cursor: pointer;
}
.tail {
  text-align: center;
  border-top: 0;
  width: 100%;
  min-height: 50px;
  font-size: 12px;
  color: #91A3AD;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  a {
    cursor: pointer;
    color: $cs-primary-color;
  }
  .confirm {
    font-size: 17px;
  }
}

.gear_l {
  animation: spin_l 4s linear infinite;
  transform-origin: 32px 32px;
}

.gear_m {
  animation: spin_m 4s linear infinite;
  transform-origin: 28px 29px;
}

.gear_s {
  animation: spin_s 3s linear infinite;
  transform-origin: 12px 12px;
}

@keyframes spin_l {
  0% {
    -webkit-transform: translate(56px, 16px) rotate(0deg);
    transform: translate(56px, 16px) rotate(0deg)
  }
  100% {
    -webkit-transform: translate(56px, 16px) rotate(360deg);
    transform: translate(56px, 16px) rotate(360deg)
  }
}

@keyframes spin_m {
  0% {
    -webkit-transform: translate(13px, 41px) rotate(0deg);
    transform: translate(13px, 41px) rotate(0deg)
  }
  100% {
    -webkit-transform: translate(13px, 41px) rotate(-360deg);
    transform: translate(13px, 41px) rotate(-360deg)
  }
}

@keyframes spin_s {
  0% {
    -webkit-transform: translate(116px, 62px) rotate(0deg);
    transform: translate(116px, 62px) rotate(0deg)
  }
  100% {
    -webkit-transform: translate(116px, 62px) rotate(-360deg);
    transform: translate(116px, 62px) rotate(-360deg)
  }
}

.upload_fail_tip {
  font-size: 15px;
  color: #BF8530;
  text-align: center;
  line-height: 3;
  border: 2px dashed #ebebeb;
  border-top: none;
  border-bottom: none;
}
