@import "../../../styles/mixins/utils.scss";
@import "../../../styles/mixins/flexbox.scss";
@import "styles/constant.scss";

:local(.search_box) {
  @include display(flex);
  @include justify-content();
  //width: 50%;
  padding: 0 6px;
  float: right;
  position: relative;

  :local(.ui_search) {
    @include flex();
    //width: 1%;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #C5C8DA;
    position: relative;
    border-radius: 4px;

    input {
      display: block;
      $padding-right: 38px;
      padding: 7px $padding-right 7px 12px;
      font-size: 14px;
      line-height: 16px;
      border: none;
      border-radius: 4px;
      //min-width: 332px;
      outline: none;
      width: 100%;
    }

    :global(.icon-search) {
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -8px;
      // width: 16px;
      // height: 16px;
      color: #e0e0e0;
      cursor: pointer;
      // font-size: 16px;
    }
    :global(.icon-search:before) {
      display: none;
    }
  }

  .btn_search {
    height: 32px;
    font-size: 13px;
    margin-left: 12px;
    margin-right: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .search_placeholder {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -8px;
    height: 16px;
    font-size: 13px;
    color: #91a3ad;
  }

  :global(.am-dropdown) {
    padding: 0;
    width: auto!important;
    :global(.am-selected-btn) {
      border-right: none;
    }
  }
}

.popover {
  position: absolute;
  z-index: 1;
  right: 6px;
  top: 12px;
}

.ui_search:hover {
  border: 1px solid var(--cs-primary-color-alt);
}
