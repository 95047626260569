@import "styles/constant.scss";

.ui_search {
  width: 1%;
}

.search_box {
  width: 40%;
}

.data_container {
  composes: data_container from 'styles/common.scss';
  :global(.Select), :global(.am-dropdown) {
    width: 25%;
  }
}
.moreOption_container {
  .search_box {
    width: 50%;
  }
  .tag_select{
    width: 50%;
    //padding-right: 70px;
  }
}


.select{
  width: 20%;
}

.searchRow {
  display: flex;
  align-items: center;
  & > * {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.select_box {
  composes: select_box from 'styles/common.scss';
  padding: 0 0 !important;
  position: relative;
}

.issue_container {
  composes: issue_container from 'styles/common.scss';
  margin-bottom: 0;
}

.reportDetailContainer {
  padding: 24px;
}

.more_opt {
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 13px;
  a {
    cursor: pointer;
  }
}

.data_container_below{
  composes: data_container from 'styles/common.scss';
  margin-top: -70px;
}

.subtitle {
  font-size:14px;
  color: $cs-main-text-color;
  text-align:left;
  margin: 15px 0;
}
