.openApiTop{
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  padding-bottom: 19px;
  font-weight: bold;
  border-bottom: 1px solid #F9F8FA;
}
.openApiTitle{
  font-size: 14px;
  color: #393157;
  line-height: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.openApiBody{
  background: #FAFAFD;
  padding: 20px;
  .generateTitle {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 32px;
  }
  .generateBottom {
    background: var(--cs-primary-color);
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    height: 32px;
    border: 1px solid var(--cs-primary-color);
  }
  .userOpenApiKeyInput {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .userOpenApiKeyReset{
    height: 32px;
    background: var(--cs-primary-color);
    border-radius: 4px;
    color: #fff;
    border: 1px solid var(--cs-primary-color);
  }
  .openApiModal {
    display: flex;
    gap: 4px;
    line-height: 14px;
    .openApiModalIcon {
      transform: rotate(180deg);
      height: 14px;
      width: 14px;
    }
  }

}
