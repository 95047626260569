@import '~styles/mixins/variables.scss';

.tag_mgr{
  composes: product_problem from 'styles/common.scss';
  :global(.btn){
    height: 38px;
    line-height: 38px;
  }
}

.tag_mgr_list{
  min-height: 560px;
  :global{
    .ant-table-thead > tr > th {
      line-height: 14px;
    }
    .ant-table-thead > tr > th, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
      padding: 5px 0px 5px 10px;
    }
    .ant-table-tbody > tr > td {
      padding: 4px 10px 4px 0px;
      border-bottom-width: 0px;
    }
    .ant-table-tbody > tr:first-child > td {
      padding: 8px 10px 4px 0px;
    }
    .ant-table-tbody > tr > td:last-child {
      padding: 4px 0px 4px 0px;
    }
    .ant-btn {
      padding: 0px;
    }
    .ant-input {
      border-radius: 4px;
    }
  }
}


.tag_table{
  font-size: 14px;
}
.tag_mgr_top{
     margin:30px 30px 0 30px;
     height: 40px;
     line-height: 40px;
     font-size: 13px;
     box-sizing: border-box;
}
.select_box {
  composes: select_box from 'styles/common.scss';
}

.search_box{
    display: block;
    width: 240px;
    float: left;

    .ui_search{
        height: 38px;
        input{
            padding-top:9px;
            padding-bottom: 9px;
        }
    }
}
.tag_container{
    width: 50%;
    margin-top: 18px;
    border-right: 1px solid #efefef;
}
.tag_title{
    font-size: 0;
    background:#fcfcfc;
    height:42px;
    box-sizing: border-box;
    line-height: 40px;
    border-top:1px solid #efefef;
    border-bottom:1px solid #efefef;
    padding-left: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    div{
        display: inline-block;
        font-size:13px;
        color:#273238;
        text-align:left;
    }
    .tag_id_title{
        width: 70px;
    }
    .tag_name_title{
        width: 230px;
    }
    .tag_problem_title{
        width: 79px;
    }
    .tag_do_title{
        width: 47px;
        padding-left: 20px;
    }
}

.tagname_error{
  input{
     border: 1px solid $global-color-red !important;
  }
  .help_inline{
    color: $global-color-red;
  }
}

.help_inline{
  color: $global-color-gray;
}

.addtag_modal, .error_modal{

    :global(.am-modal-btn):last-child{
         border-right: $btn-default-border;
      }
}
