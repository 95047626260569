.goto_top_btn {
  position: fixed;
  right: 24px;
  bottom: 180px;
  height: 54px;
  width: 54px;
  z-index: 999;
  background-image: url('images/v2/newcs_dashboard_gototop_icon.png');
  &:hover {
    background-image: url('images/v2/newcs_dashboard_gototop_icon_hover.png');
  }
}