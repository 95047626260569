@import "styles/constant.scss";
.page_header {
  /* position: fixed; */
  z-index: 2;
  background-color: white;
}

.page_header_fixed {
  position: sticky;
  padding: 12px 0 16px 0;
  z-index: 2;
  background-color: white;
}

.icon_filter {
  margin-top: -3px;
  margin-right: 4px;
}

.select_box {
  composes: select_box from 'styles/common.scss';
  :global(.am-selected) {
    padding: 0;
    width: 100%;
  }
  :global(.ant-picker-large .ant-picker-input > input) {
    font-size: 14px !important;
  }
}

.search_box {
  float: none;
  display: inline-block;
  width: 100%;
  padding: 0;
}

.senior_search_btn {
  display: inline-block;
  padding-left: 13px;
  padding-top: 7px;
}

.icon_compare {
  // position: absolute;
  // right: 32px;
  // margin-top: -16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.icon_compare:hover {
  color: var(--cs-primary-color-alt);
  svg {
    path {
      fill: var(--cs-primary-color-alt);
    }
  }
}

.icon_compare_close {
  position: absolute;
  right: 32px;
  // margin-top: -7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--cs-primary-color-alt);
  svg {
    path {
      fill: var(--cs-primary-color-alt);
    }
  }
}

.icon_compare_close:hover {
  color: var(--cs-primary-color-alt);
  svg {
    path {
      fill: var(--cs-primary-color-alt);
    }
  }
}

// .expand_btn {
//   width: 32px;
//   height: 32px;
// }

// .expand_btn_arrow_top,
// .expand_btn_arrow_bottom {
//   width: 32px;
//   height: 32px;
//   background-size: cover;
//   background-repeat: no-repeat;
// }

// .expand_btn_arrow_top {
//   background-image: url('svg/v2/newcs_dashboard_crashanalysis_issuelist_unfold_normal.svg');
// }

// .expand_btn_arrow_top:hover {
//   background-image: url('svg/v2/newcs_dashboard_crashanalysis_issuelist_unfold_hover.svg');
// }

// .expand_btn_arrow_bottom {
//   background-image: url('svg/v2/newcs_dashboard_crashanalysis_issuelist_unfold_normal.svg');
//   transform: rotate(180deg);
// }

// .expand_btn_arrow_bottom:hover {
//   background-image: url('svg/v2/newcs_dashboard_crashanalysis_issuelist_unfold_hover.svg');
// }
.expand_btn {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #C5C8DA;
  border-radius: 4px;
  border: 1px solid #C5C8DA;
  user-select: none;
  margin-top: 3px;
  cursor: pointer;

  &:hover .expand_btn_arrow_top {
    border-bottom: 7px solid #666;
  }
  &:hover .expand_btn_arrow_bottom {
    border-top: 7px solid #666;
  }
}

.expand_btn_arrow_top {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #393157;
  font-size: 0;
  line-height: 0;
}

.expand_btn_arrow_top:hover {
  border-bottom: 6px solid #666;
}

.expand_btn_arrow_bottom {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #393157;
  font-size: 0;
  line-height: 0;
}

.expand_btn_arrow_bottom:hover {
  border-top: 6px solid #666;
}

.expand_search_bar_box {
  background: #f5f6fa4d;
  border-radius: 4px;
  border: 1px solid #C5C8DA;
  padding: 16px 22px 20px 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.search_condition_bar_box {
  background: #f5f6fa4d;
  border-radius: 4px;
  border: 1px solid #C5C8DA;
  padding: 8px 12px 17px 12px;
}

.compare_title_span {
  border: 1px solid #c5c8da66;
  border-radius: 4px;
  padding: 5px 7px 5px 8px;
  color: #807997;
  font-size: 14px;
}
.not_compare_title_span {
  border: 1px solid #c5c8da66;
  border-radius: 4px;
  padding: 2px 7px 2px 4px;
  color: #807997;
  font-size: 12px;
  margin-right: -4px;
  display: flex;
  align-items: center;
  gap: 4px;
}
