.modalFooter {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'end';
  :global {
    .ant-btn {
      width: 64px;
      padding: 0px;
    }
    .ant-btn:not(.ant-btn-icon-only) {
      padding: 0px;
    }
  }
}
