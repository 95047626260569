@import "styles/constant.scss";
.legacyTimePicker {
  :global(.rdrDayToday span:after) {
    background: none;
  }
  cursor: pointer;
  :global(.rdrPprevButton i):hover {
    border-color: transparent var(--cs-primary-color-alt) transparent transparent;
  }
  :global(.rdrNextButton i):hover {
    border-color: transparent transparent transparent var(--cs-primary-color-alt);
  }
  :hover {
    border-color: var(--cs-primary-color-alt);
  }
}
