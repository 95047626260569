.crashSightSubheader {
  min-height: 80px;
  background: #FFFFFF;
  padding-top: 12px;
  padding-left: 32px;
  box-shadow: 0 2px 10px 0 rgba(49,68,124,0.15);

  .title {
    color: #666666;
    font-size: 14px;
  }
}
