@import "styles/constant.scss";

.sub_form {
  p {
    font-size: 14px;
    color: #282828;
  }
  div {
    padding-top: 20px;
    label {
      display: inline-block;
      font-size: 13px;
      color: #282828;
      width: 85px;
      .necessary {
        color: #DC5975;
      }
    }
    input {
      border: 1px solid #E0E0E0;
      border-radius: 2px;
      height: 36px;
      width: 300px;
      padding: 8px;
      margin-left: 20px;
    }
  }
  .operation {
    border-top: 1px solid #E0E0E0;
    padding: 20px 0 0 0;
    vertical-align: middle;
    height: 83px;
    a {
      float: right;
      font-size: 13px;
      padding: 9px 27px;
      cursor: pointer;
    }
    .cancel {
      border: 1px solid #E0E0E0;
      border-radius: 2px;
    }
    .submit {
      background: $cs-primary-color;
      border: 1px solid #3990D7;
      border-radius: 2px;
      color: #FFFFFF;
      margin: 0 30px 0 12px;
    }
  }
  .alert {
    input {
      border: 1px solid #F44336;
      box-shadow: 0 4px 8px 0 rgba(244,67,54,0.10);
    }
    .error {
      font-size: 12px;
      color: #F44336;
      margin-left: 12px;
    }
  }
}
