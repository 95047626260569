@import "styles/constant.scss";

.error_title{
  composes: error_title from 'styles/common.scss';
  font-size:14px;
  line-height: 24px;
  font-weight: 500;
  word-break: break-all;
  width: 100%;
  white-space: normal;
}


.check_all {
  position: absolute;
  left: 16px;
  bottom: 10px;
}

.checked_fun_btn {
  color: $cs-primary-color;
  font-size: 14px;
  background: #FFFFFF;
  border: 1px solid $cs-primary-color;
  border-radius: 4px;
}

.error_info {
  margin-top: 0;
  font-size: 13px;
  color: #4C4C4C;
  line-height: 20px;
  overflow: hidden;

  &.true {
    display: none;
  }

  .less_info {
    max-height: 60px;
    cursor: pointer;
  }

  .key_stack {
    display: inline-block;
    margin: 0;
    white-space: normal;
    max-width: 90%;
    vertical-align: top;
  }

  .crash_detail {
    background: #FDFDFD;
    border: 1px solid #EBEBEB;
    font-size: 12px;
    color: #4C4C4C;
    padding: 12px 16px;
    margin: 4px 0 7px;
    line-height: 20px;
    ol > li {
      list-style: decimal inside none;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btns {
      overflow: hidden;
      color: $cs-primary-color;

      a {
        color: $cs-primary-color;
      }
    }
}

> div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
}
