@import "styles/constant.scss";

.empty_data {
  margin: 0 auto;
  text-align: center;
  padding: 120px 0 40px;
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 1px solid #E8E8E8;
    margin: 0 auto;
    svg {
      margin-top: 28px;
    }
  }
  .headline {
    font-size: 14px;
    color: #282828;
    margin-top: 30px;
  }
  .subline {
    font-size: 13px;
    color: #888888;
    line-height: 22px;
    margin-top: 8px;
  }
  a {
    cursor: pointer;
    background: $cs-primary-color;;
    border: 1px solid #3990D7;
    border-radius: 2px;
    font-size: 13px;
    color: #FFFFFF;
    padding: 11px 47px;
    margin-top: 30px;
    display: inline-block;
  }
  .second {
    padding: 11px 28px;
    margin-left: 20px;
  }
}
