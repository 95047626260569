@import "styles/constant.scss";

.statusModal {
  :global {
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: '';
      display: none;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .ant-form-item-explain-error {
      color:#F44336;
    }
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      border-color: #F44336 !important;
    }
    .ant-btn {
      width: 64px;
      padding: 0 !important;
    }
    .ant-form-item-with-help .ant-form-item-explain {
      margin-bottom: 2px;
    }
  }
}

.statusSelectItem {
  display: flex;
  align-items: center;
  gap: 4px
}