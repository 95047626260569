@import "../../../../styles/mixins/flexbox.scss";
@import "../../../../styles/mixins/global.scss";
@import "styles/constant.scss";

.closing_btn {
  text-align: right;
  position: absolute;
  right: 20px;
  bottom: 22px;
  color: #91A3AD;
  span {
    font-size: 14px;
    padding-right: 20px;
    margin-left: 5px;
  }
}

.logError {
  color: red;
}

.title {
  font-size: 14px;

  .link {
    font-size: 14px;
    cursor: pointer;
  }
}

.notLastReportNoticeBlock {
  margin-top: 8px;
}

.issue_container {
  composes: issue_container from 'styles/common.scss';
}

.data_container {
  composes: data_container from 'styles/common.scss';
  padding: 0;

  .data_head {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    padding: 14px 16px;
    box-sizing: border-box;
    line-height: 24px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > *:not(:last-child) {
      margin-right: 5px;
    }

    :global(.icon-stack) {
      font-size: 18px;
      color: $cs-primary-color;
      margin-right: 14px;
      margin-top: 40px;
    }
    .name_wrapper {
      @include flex();
      .data_id {
        font-size: 14px;
        color: $cs-link-color;
        line-height: 24px;
      }
      .data_subid {
        font-size: 12px;
        color: #4C4C4C;
        line-height: 18px;
        white-space: normal;
        padding-right: 5px;
      }
    }
  }

  .data_item {
    width: 100%;
    overflow: visible;
    .firstStack{
      font-size: 12px;
      color: #888888;
      display: none;
    }
    &:global(.unactive) {
      z-index: 1;
      position: relative;
      .data_detail {
        display: none;
      }
      .data_head {
        box-shadow: none;
      }
      .firstStack{
        display: inline-block;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 5px;
        right: 5px;
        height: 10px;
        border: 1px solid #EBEBEB;
        background: #fff;
        z-index: -1;
        transition: transform .5s ease-out;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 10px;
        right: 10px;
        height: 10px;
        border: 1px solid #EBEBEB;
        transition: transform .5s ease-out;
        z-index: -2;
      }
      &:hover {
        &:before {
          transform: translate(0, 4px);
        }
        &:after {
          transform: translate(0, 8px);
        }
      }
    }
  }

  .data_detail {
    border: 1px solid #EBEBEB;
    border-collapse: collapse;
    margin: 0;
    width: 100%;
    table-layout: auto;
    color: #4C4C4C;
    transform: translate3d(0,0,0);
    font-family: Consolas, Menlo, Monaco, “DejaVu Sans Mono”, “Courier New”, monospace;
    .highLight{
      color: $cs-link-color;
      font-weight: bold;
      .index {
        font-weight: normal;
      }
    }
    .nohighlight {
      overflow: hidden;
    }
    .logError{
      color: $cs-primary-color-red;
    }

    .logWarn {
      color: $cs-primary-color-orange;
    }
    &.data_detail_origin {
      tr .addr {
        max-width: 60%;
        @extend %G-hide-overflow-text;
      }
      tr .libName{
        max-width: 40%;
      }
    }
    &.data_detail_analyzed {
      color: #4C4C4C;
      tr td.col2 {
        position: relative;
        margin-right: -1px;
        border-right: 1px solid #EBEBEB;
        white-space: normal;
        .index {
          position: absolute;
          left: 0;
        }
      }
    }

    &.data_detail_log {
      tr td {
        white-space: normal;
        &:first-child {
          padding-left: 50px;
        }
        .index {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &.is_ios {
      tr {
        td:first-child {
          @extend %G-hide-overflow-text;
        }
      }
    }


    tr {
      position: relative;
      word-break: break-all;
      white-space: normal;
      &:hover {
        background-color: #fefce8 !important;
      }

      td {
        vertical-align: middle;
        position: relative;
        &:first-child {
          padding-left: 0;
        }
        .index {
          background: #F8F8F8;
          border-right: 1px solid #EBEBEB;
          opacity: 0.89;
          text-align: right;
          width: 40px;
          vertical-align: middle;
          padding-right: 4px;
          margin-right: 10px;
          color: #4C4C4C;
          display: inline-block;
          height: 100%;
          &:after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 100%;
            vertical-align: middle;
            visibility: hidden;
          }
        }
      }

      .addr{
        padding-left: 10px;
        white-space: normal;
      }
      &.hide_overflow_text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
      line-height: 31.4px;
      font-size: 14px;
      border-bottom: 1px solid #f6f6f6;
    }
  }

  .data_table {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
    tr {
      height: 32px;
      border-bottom: 1px solid #e0e0e0;
      &:last-child {
        border-bottom: none;
      }
      td {
        padding-left: 12px;
        border-right: 1px solid #e0e0e0;
        overflow: auto;
        white-space: normal;
        word-break: break-all;
        vertical-align: middle;
        line-height: 25px;
        &:last-child {
          border-right: none;
        }
      }
      .label {
        background: #F8F8F8;
        width: 18%;
      }
      .value {
        width: 32%;
      }
      .keyValue {
      }
      .keyType {
        width: 50px;
      }
    }
    .headStyle:first-of-type {
      background: #F8F8F8 !important;
    }
  }

}

 .search_box {
    width: 60%;
    position: relative;
  }

.pb60 {
  padding-bottom: 60px !important;
  position: relative;
}

.select_box {
  margin-top: 20px;
  composes: select_box from 'styles/common.scss';
  :global(.am-selected) {
    width: 20%;
  }
}


.data_table .double_cols {
  overflow: visible;
}

.issue_container .data_container .data_table .fix_width {
  width: 82%;
}

.otherThreadButtonArea {
  height: 48px;
  margin-top: 16px;
  color: $cs-link-color;
  display: flex;
  justify-content: center;

  .otherThreadButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 2px;

    &.collapsed {
      path {
        transform: scale(1.2);
        transform-origin: 130px 140px;
      }
    }

    &.expanded {
      path {
        transform: scale(1.2);
        transform-origin: 130px 100px;
      }
    }
  }
}

.icon-export {
  line-height: 60px !important;
  margin-right: 30px;
}

.mod_checkbox {
  position: relative;
  left: 0;
  top: 0;
  margin-left: 12px;
  margin-top: 10px;
  width: 72px;

  .ui_checkbox {
    font-size: 12px;
    width: 100%;
    padding-left: 24px;
    line-height: 19px;
  }
}

:global(.margintop20) {
  margin-top: 20px !important;
}

:global(.marginbottom20) {
  margin-bottom: 20px !important;
}

.thread_name {
  font-size: 14px;
  color: $cs-link-color;
  line-height: 24px;
}

.no_content{
  font-size: 15px;
  text-align: center;
  margin-top: 40px;
}

.lastReportPrefix {
  color: $cs-sub-text-color;
  padding-right: 8px;
  border-right: 1px solid #EEE;
  margin-right: 8px;
}

.crashId{
  color: $cs-main-text-color;
  font-size: 14px;
}
.IconHover:hover{
  svg {
    path {
      fill:#A452DD;
    }
  }
}

.normal_wrap {
  white-space: normal;
  position: relative;
  padding-left: 50px;
  .index {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.line_pre{
  padding-left: 50px;
}
