@import '../../../../styles/mixins/global.scss';

.issue_head {
  width: 100%;
  font-size: 14px;

  .error_title {
    word-break: break-all;
    width: 100%;
    white-space: normal;
    display: flex;
    gap: 20px;
  }

  .statsArea {
    margin-top: 40px;
    display: flex;
    gap: 20px;
  }

  .tagArea {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }

  .issueHeadItem{
    width: fit-content;
  }

  .statsTitle {
    font-size: 12px;
    text-align: center;
  }

  .statsValue {
    margin-top: 32px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li:first-child {
    width: 62%;
    border-left: none;
    padding-top: 0;
  }
  li:last-child {
    width: 18%;
    padding-top: 15px;
    .error_man {
      text-align: center;
      left: 0;
      right: 0;
    }
  }
  li {
    display: inline-block;
    width: 10%;
    height: 100%;
    font-size: 12px;
    border-left: 1px solid #EBEBEB;
    padding-top: 20px;
    vertical-align: top;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .error_info {
    line-height: 1.5;
    @extend %G-hide-overflow-text;
  }

  .issueTrendArea {
    margin-top: 8px;
    display: flex;
    gap: 24px;
  }

  .issueTrendItem {
  }

  .issueTrendTitle {
    color: #AAA;
    user-select: none;
  }

  .strong_number {
    font-size: 20px;
    color: #21292F;
    line-height: 2;
  }
  .affect {
    color: #888888;
    padding-left: 15px;
  }
  .commander {
    text-align: center;
  }

  .error_man {
    position: absolute;
    bottom: 20px;
    right: 18px;
    font-size: 12px;
    color: #888888;
    line-height: 14px;
    text-align: right;
    span:first-child {
      margin-right: 5px;
    }
    .status_processing {
      color: #9bd560;
    }
    .status_wait {
      color: #ed7886;
    }
  }
  :global(.btn) {
    margin-right: 0;
  }
  :global(.status_change) {
    font-size: 12px;
    line-height: 44px;
    text-align: left;
  }
}
