@import "../../../../styles/mixins/flexbox.scss";
@import "../../../../styles/mixins/global.scss";
@import "styles/constant.scss";

.likes {
    display:flex;
    svg {
        display: block;
        width: 20px;
        height: 20px;
        path {
          fill: '';
        }
      }
  }