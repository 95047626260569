@import "../../../../styles/mixins/global.scss";
@import "../../../../styles/mixins/utils.scss";
@import "styles/constant.scss";

.timeline_container {
  padding: 24px;
  font-family: $cs-font-light;

  .input_area {
    position: relative;
    :global(.btn) {
      position: absolute;
      right: 0;
      bottom: -66px;
      margin-right: 0;
    }
  }

  textarea {
    margin: 0 8px;
    width: 100%;
    height: 120px;
    background-color: #FCFCFD;
    border: 1px solid #C5C8DA;
    outline: none;
    padding: 12px;

    &::placeholder {
      opacity: 0.6;
    }
  }
  .timeline {
    font-size: 12px;
    flex-grow: 1;
    padding-bottom: 50px;
    margin-left: 30px;
    color: #888;
    li {
      padding: 24px 0 0 16px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        left: -3px;
        top: 30px;
        background: #fff;
        z-index: 2;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-left: 1px solid #C5C8DA;
        z-index: 1;
      }
      &:first-child:before {
        border: 1px solid #C5C8DA;
      }
      &.status {
        .time, .man {
          font-size:12px;
          color: $cs-sub-text-color;
        }
        .info {
          color: $cs-main-text-color;
          margin-top: 8px;
        }
        .bubble {
          color: #4C4C4C;
          border: 1px solid #C5C8DA;
          min-height: 32px;
          display: flex;
          align-items: center;
          padding: 4px 12px;
          box-sizing: border-box;
          margin-top: 8px;
          position: relative;
          word-break: break-all;
          white-space: pre-wrap;
        }
      }

      &.status_right:before, &.status_keyboard:before, &.status_waiting:before, &.status_reopened:before {
        border-radius: 0;
        height: 14px;
        width: 14px;
        top: 27px;
        left: -6.5px;
      }

      &.status_right:before {
        background: #FFF url('svg/v2/newcs_dashboard_list_status_icon3.svg') no-repeat center;
      }
      &.status_reopened:before {
        background: #FFF url('svg/v2/newcs_dashboard_list_status_icon4.svg') no-repeat center;
      }
      &.status_message:before {
        width: 7px;
        height: 7px;
        background: $cs-main-text-color;
        z-index: 2;
      }
      &.status_waiting:before {
        background: #FFF url('svg/v2/newcs_dashboard_list_status_icon2.svg') no-repeat center;
      }
      &.status_keyboard:before {
        background: #FFF url('svg/v2/newcs_dashboard_list_status_icon1.svg') no-repeat center;
      }
      &.first_time {
        border-left: none;
        &:before {
          width: 7px;
          height: 7px;
          background: $cs-main-text-color;
          z-index: 2;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 34px;
          border-left: 1px solid #C5C8DA;
          z-index: 1;
        }
        .time {
          color: $cs-sub-text-color;
        }
        .tip {
          font-size: 12px;
          color: $cs-main-text-color;
        }
      }
    }
  }
}
