.csTabs {
  :global(.ant-tabs-nav) {
    margin: 0 !important;
    &::before {
      border-bottom: 0 !important;
    }
  }

  :global(.ant-tabs-tab) + :global(.ant-tabs-tab) {
    margin: 0 0 0 10px;
  }
  &.bigSize {
    :global(.ant-tabs-tab) + :global(.ant-tabs-tab) {
      margin: 0 0 0 4px;
    }
  }

  :global(.ant-tabs-tab) {
    height: 24px;
  }
  &.bigSize {
    :global(.ant-tabs-tab) {
      height: 32px;
    }
  }

  :global(.ant-tabs-tab) {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: #807997;
  }
  &.bigSize {
    :global(.ant-tabs-tab) {
      color: #393157;
    }
  }

  :global(.ant-tabs-tab-active) {
    background: linear-gradient(#FFEADD, #FFFBF9);
    color: var(--cs-primary-color-alt);
    border-radius: 4px;
  }
  &.bigSize {
    :global(.ant-tabs-tab-active) {
      background: rgba(var(--cs-primary-color-alt-rgb-params), 0.1);
    }
  }

  // antd Tabs选中的项目下面的一条粗线
  :global(.ant-tabs-ink-bar) {
    height: 0 !important;
  }

  :global(.ant-table-thead > tr > th) {
    background: #F5F5F5;
  }

  :global(.ant-table-cell) {
    color: #807997;
    font-size: 14px;
  }
}

/**
  由于tabs在没有传递 tabBarExtraContent 的情况下，出现ant-tabs-nav-operations-hidden失效的问题
  所以再没有传递tabBarExtraContent时，直接将对应的div不展示
  */
.csTabsHiddenOperations {
  :global {
    .ant-tabs-nav-operations {
      display: none !important;
    }
  }
}
