@import "styles/constant.scss";

.chatBotPopup {
  width: 640px;
  height: 800px;
  position: fixed;
  top: calc(#{$cs-main-header-height} + 16px);
  right: 24px;

  box-shadow: 0 3px 6px -4px #0000001f,0 6px 16px #00000014,0 9px 28px 8px #0000000d;

  .loadingPlaceholder {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .chatBotHeader {
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 100%;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-image: linear-gradient(to right, rgb(37, 99, 235), rgb(14, 165, 233));
  }

  .chatBotIframeContainer {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .chatBotIframeInner {
    position: absolute;
    top: -44px;
    left: 0;
    width: 100%;
    height: calc(100% + 44px);
  }

  .closeIconButton {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
