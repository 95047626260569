.container {
  font-size: 12px;
  position: relative;
}
.otherConfig{
  width: 100%;
  :global(.ant-form-item){
    margin: 0;
  }
  :global(.ant-switch-small) {
    min-width: 28px;
    height: 16px;
    line-height: 16px;
    margin-bottom: 3px;
  }
}