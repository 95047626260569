@import "styles/constant.scss";

.pager {
  width: 100%;
  height: 32px;
  font-size: 13px;
  padding: 0 30px;

  .pagenum_select {
    float: left;

     label{
       margin-right: 10px;
     }

     :global(.am-selected){
       width: 60px;
       height: 32px;

       :global(.am-btn){
         padding: 5px 1em;
         height: 32px;
         border-radius: 4px;
       }
     }
  }

  :global(.pagination) {
    text-align: right;
    height: 32px;

    > li {
      display: inline-block;
      margin-right: 10px;
      text-align: center;
      vertical-align: top;

      &:global(.previous), &:global(.next) {
        a {
          width: 70px;
        }
      }

      &:global(.active) {
        a {
          background: $cs-primary-color;;
          color: #fff;
        }
      }
      &:global(.break) {
        display: none;
      }
      &:global(.break){
        a{
          cursor: default;
        }
      }
    }

    a {
      display: block;
      min-width: 40px;
      padding: 0 4px;
      height: 32px;
      box-sizing: border-box;
      font-size: 13px;
      line-height: 32px;
      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
