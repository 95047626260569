.crashDetail {
  background: #FDFDFD;
  border: 1px solid #EBEBEB;
  padding: 18px 24px 18px;
  font-size: 12px;
}

.crashDetailHeader {
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
  :global {
    .ant-pagination.ant-pagination-mini .ant-pagination-item, .ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
      display: none;
    }
    .ant-pagination.ant-pagination-mini .ant-pagination-jump-prev, .ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
      display: none;
    }
    .ant-pagination.ant-pagination-mini .ant-pagination-prev {
      margin-right: 8px;
    }
  }
}
