@import "styles/constant.scss";

.report_detail {
  width: 100%;
  padding: 27px 30px;
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    white-space: initial;
    li {
      width: 25%;
      display: inline-block;
      text-align:left;
      margin-bottom: 24px;
      vertical-align: top;
      white-space: pre-wrap;
      overflow-wrap: anywhere;
      padding-right: 10px;
      .label {
        font-size: 12px;
        line-height: 12px;
        color: $cs-sub-text-color;
        margin-bottom: 8px;
      }
      .detail {
        font-size: 14px;
        line-height: 16px;
        color: $cs-main-text-color;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        overflow-wrap: anywhere;
        margin-bottom: 0;
      }
    }
    .fixwidth{
      width: 100%;
    }
  }

  &.reportDetailSmallSize {
    padding: 8px;
    ul {
      li {
        margin-bottom: 20px;
        .detail {
          font-size: 14px;
        }
      }
    }
  }
}

.link {
  color: $cs-primary-color;;
  font-size: 13px;
  cursor: pointer;
  display: inline-block;
}
