@import "../../../../styles/mixins/flexbox";
@import "../../../../styles/mixins/utils.scss";
@import "styles/constant.scss";

.summary_tab {
  @include display();
  @include justify-content(space-between);
  min-width: 960px;
  padding-bottom: 5px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10);
  //margin-left: -10px;
  //margin-right: -10px;
}

.summary_tab_item {
  position: relative;
  padding: 0;
  width: 1%;
  // height: 100px;
  // background:rgba(32,40,47,0.30);
  background: #FFF;
  color: #393157;
  // border-radius: 4px;
  position: relative;
  padding: 20px 0px 20px 24px;
  margin-right: 11px;
  cursor: pointer;
  @include flex();
  // margin-right: 1px;
  &:last-child {
    margin-right: 0;
  }
  &:not(.active):hover {
    color: var(--cs-primary-color-alt);
  }

  .filterStrategyMark {
    position: absolute;
    top: 14px;
    right: 14px;
  }

  .filterStrategyMarkIcon {
    width: 22px;
    height: 22px;
    border: 1px solid rgba(197, 200, 218, 0.4);
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .summaryTabItemGray {
    font-size: 14px;
    // color: #807997;
    font-weight: 300;
    // margin-left: 20px;
    // padding-top: 4px;
  }

  &.itemDisabled {
    cursor: not-allowed;
    color: $cs-sub-text-color;
    opacity: 0.4;
  }

  &.active {
    .summaryTabItemGray {
      // color: #fff;
    }
    background: linear-gradient(0deg, rgba(var(--cs-primary-color-rgb-params), 0.1), rgba(255, 255, 255, 0.1));

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0;
      left: 50%;
      bottom: -5px;
      transform: translateX(-50%);
      // border-left: 6px solid transparent;
      // border-right: 6px solid transparent;
      border-top: 4px solid $cs-primary-color;
    }
    // }
  }
}

.summaryTabMainRow {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.summary_tab_item_key {
  font-size: 18px;
  line-height: 36px;
  font-weight: bold;
}

.summary_tab_item_value {
  padding-left: 8px;
  font-size: 28px;
  line-height: 40px;
  height: 40px;
  font-weight: bold;
  .percent {
    height: 40px;
    line-height: 40px;
  }
  .percent:after {
    content: '%';
    font-size: 20px;
    margin-left: 10px;
  }

  // @media (max-width: 1600px) {
  //   font-size: 28px;
  //   padding-left: 4px;
  // }
}

.summary_tab_vm_pm_div {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 16px;
  margin-top: 4px;
  // position: absolute;
  // top: 75%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // width: 100%;
}
