@import "../../../styles/mixins/utils.scss";
@import "styles/constant.scss";

.account_box{
  .account_header{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #393157;
    font-weight: bold;
    margin-bottom: 19px;
  }
  .account_box_ul{
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid #F9F8FA;
    .account_box_li{
      padding: 20px;
      font-size: 14px;
      color: #282828;
      clear: both;
      overflow: hidden;
      background-color: #FAFAFD;
      display: flex;
      align-items: center;
      .accout_item{
        flex: 1;
      }
      .account_box_dl{
        margin: 0;
        padding-left: 15px;
        border-left: 1px solid #E8E7EE;
        .account_box_dd,.account_box_dt{
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
  }
  .accout_name{
    font-size: 14px;
    color: #393157;
    margin-right: 16px;
  }
  .action_btn{
    width: 64px;
    height: 32px;
    background-color: $cs-primary-color;
    border-radius: 4px;
    text-align: center;
    a{
      font-size: 14px;
      color: #fff;
      text-decoration: none;
    }
    a:hover {
      text-decoration: none;
    }
    padding-top: 6px;
    float: right;
    color: $cs-primary-color;;
    cursor: pointer;
  }
  .user_icon{
    width: 22px;
    height: 22px;
    vertical-align: -5px;
  }
  .desc{
    margin: 10px 0 0 0;
    font-size: 14px;
    color: #807997;
    line-height: 14px;
  }
  .hidden{
    display: none;
  }
}

.code_box{
  line-height: 18px;
  img{
    float: left;
    border:5px solid #ffffff;
    width: 118px;
    height: 118px;
  }
  ul{
    padding: 0 10px 0 128px;
    li{
      padding-bottom: 10px;
      strong{
        color: #f0943c;
        font-weight: normal;
        padding: 0 5px;
      }
      &:last-child{
        padding-bottom: 0;
        padding-top: 40px;
      }
    }
  }
}

.tooltip_ico{
  display: inline-block;
  @include bg-image("images/tips.png", 13px, 13px);
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
  margin-top: -4px;
  &:hover .tooltip_box{
    display: block;
    z-index: 3;
  }
}

.tooltip_box{
  display: none;
  opacity:0.8;
  background:#2e3842;
  border-radius:4px;
  width:360px;
  padding: 10px;
  position: absolute;
  top:214px;
  left:188px;
  color: #888;
  font-size: 12px;
  p{
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    line-height: 20px;
    strong{
      color: #f0943c;
      font-weight: normal;
      padding: 0 5px;
    }
  }
}
