@import "styles/constant.scss";

.tab_container {
  height: 24px;
  &.bigSize {
    height: 32px;
  }
  border-bottom: 1px solid #EBEBEB;
  .icon-export {
    line-height: 24px;
    margin-right: 30px;
  }
}

.tab_item {
  margin-left: 30px;
  font-size: 12px;
  color: #807997;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  cursor: pointer;
  padding: 0px 10px;
  border-radius: 4px;
  font-family: $cs-font-light;

  &:hover {
    color: var(--cs-primary-color-alt);
  }

  &:global(.active) {
    font-family: $cs-font-main;
    color: var(--cs-primary-color-alt);
    position: relative;
    background: linear-gradient(to bottom, #ffeadd, #fffbf9);
    // &:after {
    //   content: '';
    //   opacity: 0.89;
    //   background: $cs-primary-color;
    //   width: 100%;
    //   height: 4px;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    // }
  }
}
