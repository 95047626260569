@import "styles/constant.scss";

.stackLineMemoryInfo {
  padding: 20px 0 24px 52px;
  background-color: #efeffb;
  font-family: $cs-font-main;

  pre {
    border: none;
    background: none;
    margin: 0;
    font-size: 14px;
    padding: 0;
  }
}
