@import "styles/constant.scss";


.status_item {
  &:hover {
    .status_btn{
      display: block;
    }
    .hover_text {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  &:not(:hover) {
    .displayOnHover {
      display: none;
    }
    .hover_text {
      display: none;
    }
  }
  &.disabled {
    background: #fafafa;
    color:#CCCCCC;

    .error_info, .time {
      color:#CCCCCC;
    }

    .doneMsg{
      color: #888888;
    }

    .gray{
      color:#CCCCCC;
    }

    .error_title {
      color:#6E9FC8;
      font-size: 14px;
      text-decoration: line-through;
    }
    .error_info, .key_stack {
      text-decoration: line-through;
    }
  }
  :global(.status_processing){
    font-size: 12px;
    &:before{
      margin-right: 0;
    }
  }
  :global(.status_not_process){
    font-size: 12px;
    &:before{
      margin-right: 0;
    }
  }
  :global(.status_processed){
    font-size: 12px;
    &:before{
      margin-right: 0;
    }
  }

}

.status_btn{
  color: $cs-primary-color;
  display: block;
  font-size: 14px;
  cursor: pointer;
}

.status_name_show {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
  word-break: break-all;
  cursor: pointer;
}

.status_tooltip {
  padding-top: 0px !important;
  :global {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        display: none;
      }
    }
  }
}

.status_hour_icon {
  width: 14px;
  height: 14px;
}
.status_waiting_icon {
  width: 12px;
  height: 14px;
}
.status_success_icon {
  width: 12px;
  height: 8px;
}