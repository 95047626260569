@import "styles/constant.scss";

.tag_list {
  font-size: 0;
  margin-top: 0px;
  white-space: initial;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.issueVersions{
  display: inline-block;
  vertical-align: top;
  height: 18px;
  line-height: 16px;
  background: #F5F6F8;
  border: 1px solid #C5C8DA;
  border-radius: 2px;
  font-size: 12px;
  margin-top: 4px;
  padding: 0px 7px;
  margin-right: 4px;
  color: $cs-sub-text-color;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  line-height: 18px;
}

.antdTag {
  height: 18px !important;
  // 字体偏下，需要兼容调整才能居中
  line-height: 16px !important;
  margin-top: 4px !important;
  margin-right: 4px !important;
}

.ft {
  background: #f9fafb;
  border: 1px solid #d9dfe3;
  border-radius: 2px;
  height: 24px;
  display: inline-block;
  padding: 2px 6px;
  margin-top: 10px;
  margin-right: 5px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #768e9c;
  &:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAAUVBMVEWRo60AAACqrsGRpK6WqbOTpK6RpK6SpK6RpK6Upq+Tp7CSpa6SpK6Tpa+VprCTo7GSpK6RpK6SpK6SpbCSpK6RpK6SpK+Rpa6Uo6uTprGRpK/bJkzuAAAAG3RSTlOZAASVCTmQa4cnFEpCLCIPi3lUMIJ+cWQcGFwNQSVcAAABNUlEQVQ4y33TWZKEIBBF0XyAyiDiPNT+F9oRbacmNtb9oiIOUKASipm9M7gqovTRRGSnb+iwdPb5ggbiplfk9YWseqBpm/WyOQDxVl2G0kZnmwHGC1UZaohrANXzj1aiikjOHosrzQJZoOOxEyiR7ID5G+n7dDxTTNf8/wRyGYpAzVwgX95uhURKC6MNjt9BnzKERqCGbyQA+HJPDQ8ypOxlZoV07r5EgfKlorhwG4xE4Ac8Akkco26VQKq+3qCVZIO/EQY+WldTlvXPlQYAprWZaojNKF9rFeRq+kQp9MQtrQHglwdSgY8jTmSabLto6dnsALWyMYSWSlXnp1WvYQdoonIO2A+D32h+QSvu6K1eov4FLRKFF1RJhLYubVYhQ1DTZ5CwHsaokKMz4/fORdft3uBfP0IgCpvdQ/gPAAAAAElFTkSuQmCC);
    background-size: cover;
    vertical-align: middle;
    margin-right: 6px;
  }
}

.firstCrashVersion{
  display: inline-block;
  vertical-align: top;
  height: 18px;
  line-height: 18px;
}