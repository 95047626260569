
.cs_tree_select {
  :global(.ant-select-selector) {
    max-height: 160px;
    overflow-y: scroll;
    &::-webkit-scrollbar{
  
    }
    &::-webkit-scrollbar-thumb{
      width: 6px;
      background: #b3afc1;
      opacity: 0.6;
      border-radius: 2px;
    }
  }
  :global(.ant-select-clear) {
    right: 12px
  }
  :global(.ant-select-arrow) {
    right: 12px;
  }
  :global(.ant-select-arrow .anticon:not(.ant-select-suffix)) {
    pointer-events: none;
  }
}