@import "styles/constant.scss";

.psCoreDumpFileSetModal {
  .iconButton:not(:hover) {
    color: $cs-sub-text-color;
  }
  .iconButton:hover {
    svg {
      path {
        fill: $cs-primary-color;
      }
    }
  }
}
