.cs_loading_mask{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
    text-align: center;
    z-index: 9999;
}

.cs_loading_item{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -25px;
    font-size: 30px;
    color: #fff;
    // margin-top: 30%;
    z-index: 1000;
}
.loading_svg{
  position:relative;
  top: 50vh;
  background-color: rgba(0,0,0,0);
  z-index: 1000;
}
