@import "../../../../styles/mixins/global.scss";
@import "styles/constant.scss";

.data_detail {
  //counter-reset: detail;
  border: 1px solid #EBEBEB;
    border-collapse: collapse !important;
  margin: 0;
  width: 100%;
  table-layout: auto;
  color: #4C4C4C;
  font-family: Consolas, Menlo, Monaco, “DejaVu Sans Mono”, “Courier New”, monospace;
  .highLight{
    color: $cs-link-color;
    font-weight: bold;
    .index {
      font-weight: normal;
    }
  }
  .nohighlight {
    overflow: hidden;
  }


  &.data_detail_log {
    tr td {
      white-space: normal;
      &:first-child {
        padding-left: 50px;
      }
      .index {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  tr {
    position: relative;
    word-break: break-all;
    white-space: normal;
    &:hover {
      background-color: #fefce8 !important;
    }

    td {
      vertical-align: middle;
      position: relative;
      &:first-child {
        padding-left: 0;
      }
      .index {
        background: #F8F8F8;
        border-right: 1px solid #EBEBEB;
        opacity: 0.89;
        text-align: right;
        width: 40px;
        vertical-align: middle;
        padding-right: 4px;
        margin-right: 10px;
        color: #4C4C4C;
        display: inline-block;
        height: 100%;
        &:after {
          content: '';
          display: inline-block;
          width: 1px;
          height: 100%;
          vertical-align: middle;
          visibility: hidden;
        }
      }
    }

    .addr{
      padding-left: 10px;
      white-space: normal;
    }
    &.hide_overflow_text {
      @extend %G-hide-overflow-text;
    }
    height: 30px;
    line-height: 34px;
    font-size: 14px;
    border-bottom: 1px solid #f6f6f6;
  }
}
