// 包含antd hack

.filterFieldSelect {
  width: 520px;
  :global {
    .rc-virtual-list-holder {
      max-height: 80vh !important;
    }
    .rc-virtual-list-holder-inner {
      flex-direction: row !important;
      flex-wrap: wrap !important;
      padding: 4px 12px;
    }
    .ant-select-item {
      min-height: 0;
    }
    .ant-select-item-group {
      width: 100%;
      font-size: 12px;
      line-height: 16px;
      padding: 6px 0 6px 0;
    }
    .ant-select-item-option {
      width: 33.33%;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.5px;
      padding: 4px 0 4px 8px;
    }
  }
}
