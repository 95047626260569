

.menu {
  display: inline-block;
}
.itemContainer {
  display: flex;
  gap: 4px;
  padding: 0px 0px;
}

.platformIconContainer {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 9px;
  gap: 2px;
  border: 1px solid #C5C8DA66;
  border-radius: 4px;

  .iconBox {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    svg {
      height: 18px;
      width: 18px;
      path {
        fill: #807997;
      }
    }
  }

  .iconBoxActive {
    background-color: var(--cs-primary-color);
    &:hover {
      background-color: var(--cs-primary-color) !important;
      cursor: unset !important;
    }
    svg {
      path {
        fill: white;
      }
    }
  }

  .iconBoxHover {
    &:hover {
      svg {
        path {
          fill: var(--cs-primary-color);
        }
      }
    }
  }
}

.addPaltformBtn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  &:hover {
    border: 1px solid rgba(var(--cs-primary-color-rgb-params), 0.2);
    background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
  }
}

.item {
  width: 24px;
  height: 24px;
  background-color: #f5f5f5;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.item:hover {
  border: 1px solid rgba(var(--cs-primary-color-rgb-params), 0.2);
  background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
}

.itemNoHover {
  width: 24px;
  height: 24px;
  background-color: #f5f5f5;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.activeItem {
  border: 1px solid var(--cs-primary-color-alt);
  background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
}

.createProject {
  :global(.ant-checkbox-wrapper) {
    align-items: center;
  }
}
