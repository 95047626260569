@import "styles/constant.scss";

.formContainer {
  :global(.ant-form-item) {
    margin-bottom: 8px;

    :global(.ant-form-item-label) {
      padding: 0;

      :global(label) {
        font-size: 14px;
        color: $cs-main-text-color;
      }
    }
  }
}
.tapdStyle {
  :global {
    .ant-btn-link {
      padding: 0 !important;
    }
  }
}
