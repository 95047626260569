.main_content{
  min-height: 50%;
  padding-bottom: 30px;
  margin: 0 auto;
  width: 800px;
  h3{
    font-size: 14px;
    line-height: 14px;
    margin: 27px 0 21px;
    font-weight: 400;
    color: #fff;
  }
  .user_center_box{
    position: relative;
    overflow: hidden;
    font-size: 13px;
    color: #273238;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.1);
    border-radius: 2px;
    padding:0 0 40px 0;
  }
}

//标题
.title{
  text-align: center;
  h4{
    font-weight: normal;
    font-size: 16px;
    color: #282828;
    position: relative;
    height: 100px;
    line-height: 100px;
    &:before,&:after{
      content: ' ';
      display: block;
      width: 260px;
      height: 1px;
      background: #EBEBEB;
      position: absolute;
      top:50px;
    }
    &:before{
      left:75px;
    }
    &:after{
      right:75px;
    }
  }
}


//关于每个状态的样式
.status_box{
  text-align: center;
  padding-bottom: 100px;
}
