@import "styles/constant.scss";

.error_box{
  text-align: center;
}
.error_ico{
  padding: 30px 0;
}
.error_msg{
  padding-top: 10px;
}
.error_btn{
  padding-top: 10px;
  a{
    color: $cs-primary-color;
  }
}
