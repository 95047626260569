@import "../../../styles/mixins/utils.scss";
@import 'styles/constant.scss';

.userInfo_list{
  float: left;
  width: 100%;
  ul{
    li{
      clear: both;
      display: flex;
      gap: 20px;
      align-items: center;
      margin: 0;
      margin-bottom: 20px;
      font-size: 14px;
      color: #393157;
    }
  }
  :global(.btn) {
    margin-top: 7px;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
  }
}

.themeColorOrangeOptionIcon {
  width: 20px;
  height: 20px;
  background: $cs-theme-color-orange-primary-color;
}

.themeColorBlueOptionIcon {
  width: 20px;
  height: 20px;
  background: $cs-theme-color-blue-primary-color;
}

.list_item_name{
  width: 77px;
  font-size: 14px;
  color: #393157;
  letter-spacing: -0.56px;
}
.list_item_content{
  display: inline-block;
  input {
    width: 320px;
    height: 32px;
    border: 1px solid #C5C8DA;
    border-radius: 4px;
    font-size: 14px;
    color: #393157;
  }
}
.need_ico{
  color: #dc5975;
  padding-left: 2px;
}
.error{
  :global(.inputMod){
    border: 1px solid #dc5975;
  }
  .error_info{
    color: #dc5975;
    padding-left: 10px;
  }
  .city_error_info{
    position: absolute;
    top: -2px;
    right: -95px;
    color: #dc5975;
  }
}
.radio_Mod_item{
  display: inline-block;
}




.position_list{
  width: 375px;
  span{
    margin-bottom: 13px;
  }
}
.fix_vertical{
  vertical-align: 42px;
}
.desc{
  color: #888888;
  font-size: 12px;
}

.upload_account_pic{
  float: left;
  h4{
    font-size: 13px;
    height: 70px;
    line-height: 70px;
    color: #282828;
    font-weight: normal;
  }
  .user_img{
    width: 120px;
    height: 120px;
    position: relative;
    .img_box{
      width: 122px;
      height:122px;
      border: 1px solid #EBEBEB;
      @include border-radius(50%);
      img{
        width: 120px;
        height:120px;
        @include border-radius(50%);
      }
    }
    .upload_img_btn{
      display: none;
    }
    &:hover .upload_img_btn{
      font-style: normal;
      font-size: 13px;
      cursor: pointer;
      display: block;
      background:rgba(0,0,0,0.2);
      width:122px;
      height:122px;
      color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      @include border-radius(50%);
      text-align: center;
      i{
        @include bg-image("./fix.png", 18px, 18px);
        display: inline-block;
        margin-top: 42px;
      }
      p{
        height: 35px;
        line-height: 35px;
        text-shadow:0 2px 2px rgba(0,0,0,0.20);
      }
    }
  }
  .desc{
    height: 50px;
    line-height: 50px;
  }
}

.list_item_content{
  display: flex;
  align-items: center;
  :global(.radioMod){
    vertical-align: -8px;
    label{
      vertical-align: 4px;
    }
    strong{
      vertical-align: 8px;
    }
  }
  position: relative;
}

.city_error{
    height: 40px;
    line-height: 40px;
    text-align: left;
    display: inline-block;
    font-size: 13px;
}

.save_succ{
  color: #6cb132;
  margin-left: 10px;
}

.sub_title{
  font-size: 14px;
  color: #393157;
  display: flex;
  gap: 10px;
  letter-spacing: -0.56px;
  line-height: 14px;
  // padding-bottom: 43px;
  padding-bottom: 22px;
  font-weight: bold;
  align-items: center;
  border-bottom: 1px solid #F9F8FA;
  img {
    width: 14px;
    height: 14px;
  }
}
.sub_body{
  margin-top: 20px;
}
