@import "styles/constant.scss";

.main_content{
  min-height: calc(100vh - #{$cs-main-header-height} - #{$cs-main-footer-height} - 27px);
  margin: 0 auto;
  width: 1320px;
  margin-top: 27px;
  .user_center_box{
    position: relative;
    overflow: hidden;
    font-size: 13px;
    color: #273238;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.1);
    border-radius: 2px;
    display: flex;
    min-height: calc(100vh - #{$cs-main-header-height} - #{$cs-main-footer-height} - 27px);
  }
  .tab_nav{
    width: 325px;
    // border-right: 1px solid #F9F8FA;
    .tab_title {
      font-size: 16px;
      color: #393157;
      display: flex;
      gap: 8px;
      height: 72px;
      align-items: center;
      margin-left: 20px;
      font-weight: bold;
    }
    .active{
      color: #1B2227;
      position: relative;
      padding: 0 20px 0 12px;
      height: 40px;
      width: 272px;
      .text{
        width: 204px;
        line-height: 40px;
        padding: 0 12px 0 35px;
        background-color: rgba(var(--cs-primary-color-rgb-params), 0.2);
        border-radius: 8px;
        color: $cs-primary-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &:after{
        content: '';
        opacity: .89;
        background: $cs-primary-color;;
        width: 4px;
        height: 40px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  .tab_item{
    width: 293px;
    padding-left: 47px;
    font-size: 14px;
    color: #393157;
    height: 50px;
    line-height: 50px;
    letter-spacing: -0.56px;
    cursor: pointer;
  }
  .tab_container{
    flex: 1;
    margin: 0 25px;
    border: 1px solid #F9F8FA;
    margin: 20px 20px 20px 0;
    padding: 20px;
    font-size: 14px;
    .title {
      font-size: 16px;
      color: #393157;
      font-weight: 500;
      letter-spacing: -0.56px;
      line-height: 16px;
      padding-bottom: 25px;
      display: flex;
      gap: 10px;
      border-bottom: 1px solid #F9F8FA;
      img {
        width: 14px;
        height: 14px;
      }
    }

    .hidden{
      display: none;
    }
  }
}
