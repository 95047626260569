@import "styles/constant.scss";

.registerInfoContainer {
  font-size: 14px;

  .regInfoBody {
    font-family: Consolas, Menlo, Monaco, “DejaVu Sans Mono”, “Courier New”, monospace;
  }

  .reg {
    display: flex;
    border: 1px solid rgba(197,200,218,0.5);
    border-radius: 4px;
    background: #F7F7FD;
    color: $cs-main-text-color;
  }

  .regKey {
    padding: 1px 8px;
    border-right: 1px solid rgba(197,200,218,0.5);
    text-align: right;
  }

  .regValue {
    padding: 1px 8px;
  }
}
