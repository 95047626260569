.regroupRuleContainer {
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fafafd;
  border-radius: 2px;
  padding: 20px;

  :global(.ant-form-item) {
    margin-bottom: 0px;
  }
}
