@import '../../../styles/mixins/utils';
@import "styles/constant.scss";

.empty_data {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  padding: 63px 0 40px;
  .logo {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto 27px;
  }
  .tips {
    font-size: 14px;
    line-height: 16px;
    color: #807997;
    white-space: nowrap;
  }
  a {
    font-size: 13px;
    color: $cs-primary-color;
    line-height: 16px;
  }
}
