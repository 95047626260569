@import "styles/constant.scss";

.csDownloadButton {
  width: 16px;
  height: 14px;
  cursor: pointer;
  color: $cs-sub-text-color;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:not(.disabled):hover {
    color: $cs-primary-color;
  }
}
