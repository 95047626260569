.preferencesSubMenu {
  :global(a:not([disabled]):hover) {
    text-decoration: none;
  }

  :global(.ant-menu-item) {
    margin: 0 0 8px 0 !important;
    font-size: 14px;
    font-weight: 500;
    height: 32px !important;
    padding-left: 22px !important;
    padding-right :12px !important;
  }
  :global(.ant-menu-item::after) {
    border-right: none !important;
  }

  :global(.ant-menu-inline) {
    margin-top: 0;
    border: none;
  }
  :global(.ant-menu-item-selected) {
    font-weight: bold;
    border-radius: 0 8px 8px 0 !important;
  }
  :global(.ant-menu-item-selected::before) {
    content: '';
    position: absolute;
    left: 0;
    border-left: 4px solid var(--cs-primary-color);
    height: 100%;
  }
  :global(.ant-menu-title-content){
    height: 32px;
    align-items: center;
    display: flex;
  }
  :global(.ant-menu-item a) {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .menuHead{
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 16px 0;
  }
  .menuHeadIcon{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menuHeadTitle{
    font-size: 14px;
    font-weight: bold;
  }

  :global(.ant-menu-item a:hover) {
    color: var(--cs-primary-color-alt);
  }
  :global(.ant-menu-item-selected a){
    color: var(--cs-primary-color-alt);
  }
}

