@import "styles/constant.scss";

.list_header {
  background: #FCFCFC;
  color: #273238;
  //width:900px;
  height: 60px;
  line-height: 61px;
  font-size: 0;
  overflow: hidden;
  margin:2px 0 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-right: 40px;
  padding-left: 70px;
  position: relative;
}

.mod_checkbox {
  top: unset;
  position: relative;
  width: 16px;
  height: 16px;
  left: unset;
  margin-left: unset;
}

.header_fixed {
  position: sticky;
  top: 110px;
  z-index: 2;
}

.select_arrow{
  composes: select_arrow from 'styles/common.scss';
  margin-left: 5px;
  position: absolute;
  top: 50%;
  &.asc {
    transform: rotate(180deg);
    margin-top: -3px;
  }
}

.hide_content {
  :global(.ant-table-tbody) {
    display: none;
  }
  :global {
    // .ant-table-thead >tr>th::before {
    //   display: none;
    // }
    .ant-table-thead > tr > th {
      padding: 4px 0;
    }
    .ant-table-column-sorters {
      justify-content: center;
      .ant-table-column-title {
        flex: 0;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 26px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 24px;
    }
    .ant-table-column-sorters {
      height: 32px;
    }
  }

  .checked_fun_btn {
    color: $cs-primary-color;
    font-size: 14px;
    background: #FFFFFF;
    border: 1px solid $cs-primary-color;
    border-radius: 4px;
  }
}
