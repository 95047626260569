@font-face {
  font-family: 'icomoon';
  src:    url('../fonts/icomoon.eot?n2px0fe');
  src:    url('../fonts/icomoon.eot?n2px0fe#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?n2px0fe') format('truetype'),
  url('../fonts/icomoon.woff?n2px0fe') format('woff');
  font-weight: normal;
  font-style: normal;
}

// CS 2.0的英文字体
@font-face {
  font-family: 'HelveticaProRoman';
  src: url('https://cdn-v2.crashsight.wetest.net/fonts/HelveticaProRoman.ttf') format('truetype');
  ascent-override: 90%;
}

// CS 2.0的英文字体（细）
@font-face {
  font-family: 'HelveticaProLight';
  src: url('https://cdn-v2.crashsight.wetest.net/fonts/HelveticaProLight.ttf') format('truetype');
  ascent-override: 90%;
}

// CS 2.0的中文字体
@font-face {
  font-family: 'FounderLantingBlack';
  src: url('https://cdn-v2.crashsight.wetest.net/fonts/方正兰亭黑.ttf') format('truetype');
}

// CS 2.0的中文字体（细）
@font-face {
  font-family: 'FounderLantingFineBlack';
  src: url('https://cdn-v2.crashsight.wetest.net/fonts/方正兰亭细黑.ttf') format('truetype');
}

// CS 2.0欢迎页的字体
@font-face {
  font-family: 'GilroyBold';
  src: url('https://cdn-v2.crashsight.wetest.net/fonts/GilroyBold.ttf'); /* 字体文件路径和格式 */
  font-weight: normal;
  font-style: normal;
}
// CS 2.0欢迎页的字体
@font-face {
  font-family: 'GOTHIC';
  src: url('https://cdn-v2.crashsight.wetest.net/fonts/GOTHIC.TTF') format('TrueType');
  font-weight: normal;
  font-style: normal;
}
// CS 2.0欢迎页的字体
@font-face {
  font-family: 'GOTHICB';
  src: url('https://cdn-v2.crashsight.wetest.net/fonts/GOTHICB.TTF'); /* 字体文件路径和格式 */
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global(.icon-delete):before {
    content: "\e91e";
}
:global(.icon-edit):before {
    content: "\e91f";
}
:global(.icon-admin):before {
    content: "\e920";
}
:global(.icon-group):before {
    content: "\e921";
}
:global(.icon-copy):before {
    content: "\e90a";
}
:global(.icon-channel):before {
    content: "\e915";
}
:global(.icon-device):before {
    content: "\e916";
}
:global(.icon-follow):before {
    content: "\e917";
}
:global(.icon-mytask):before {
    content: "\e918";
}
:global(.icon-product):before {
    content: "\e91a";
}
:global(.icon-user):before {
    content: "\e91b";
}
:global(.icon-product2):before {
    content: "\e91c";
}
:global(.icon-misson):before {
    content: "\e91d";
}
:global(.icon-empty):before {
    content: "\e919";
}

:global(.icon-anr):before {
  content: "\e902";
}
:global(.icon-decrease):before {
  content: "\e900";
}
:global(.icon-android):before {
  content: "\e901";
}
:global(.icon-crash):before {
  content: "\e903";
}
:global(.icon-envelope):before {
  content: "\e904";
}
:global(.icon-error):before {
  content: "\e905";
}
:global(.icon-export):before {
  content: "\e906";
}
:global(.icon-hourglass):before {
  content: "\e907";
}
:global(.icon-ios):before {
  content: "\e908";
}
:global(.icon-keyboard):before {
  content: "\e909";
}
:global(.icon-menu):before {
  content: "\e90b";
}
:global(.icon-msg):before {
  content: "\e90c";
}
:global(.icon-overview):before {
  content: "\e90d";
}
:global(.icon-rectangle):before {
  content: "\e90e";
}
:global(.icon-retract):before {
  content: "\e90f";
}
:global(.icon-right):before {
  content: "\e910";
}
:global(.icon-search):before {
  content: "\e911";
}
:global(.icon-search2):before {
  content: "\e912";
}
:global(.icon-setting):before {
  content: "\e913";
}
:global(.icon-stack):before {
  content: "\e914";
}

:global(.icon-add):before {
  content: '\e922';
}
