@import "styles/constant.scss";

//符号表
.data_container {
  padding: 0;
  margin-top: 20px;

  .symbol_out_tips{
    height: 20px;
    font-size: 13px;
    color:#4c4c4c;
    line-height: 40px;
    margin: 10px 0 24px 0;
    text-align: center;
    a{
      color:$cs-primary-color;
      cursor: pointer;
      display: inline-block;
    }
  }
}

.read_rule {
  color: #91a3ad;
  .symbol_pop {
    width: 352px;
    box-sizing: border-box;
    padding: 7px 12px;
    opacity: 0.9;
    background: #2e3842;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    position: absolute;
    top: 0px;
    left: 256px;
    margin-left: -176px;
    color: #fff;
    line-height: 20px;
    font-size: 12px;
    color: #d7d9db;
    z-index: 2;
    display: none;
  }
  &:hover {
    .symbol_pop {
      display: block;
    }
  }
}

.symbol_title {
  height: 18px;
  width: 100%;
  .symbol_txt {
    color: #4c4c4c;
    padding-right: 5px;
    height: 18px;
    font-size: 13px;
    display: inline-block;
  }
  .read_rule {
    width: 75px;
    height: 18px;
    color: #91a3ad;
    font-size: 14px;
    position: relative;
    padding-left: 15px;
    margin-left: 8px;
    line-height: 1;
    display: inline-block;
    &:before {
      content: "";
      display: block;
      background: url(images/rule.png) no-repeat;
      background-size: cover;
      width: 13px;
      height: 13px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .symbol_tips {
    font-size: 12px;
    width: 445px;
    margin-right: 25%;
  }
}

.mod_symbol {
  padding-top: 11px;
  padding-bottom: 20px;
  position: relative;
  .symbol_pop {
    width: 352px;
    box-sizing: border-box;
    padding: 7px 12px;
    opacity: 0.9;
    background: #2e3842;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    position: absolute;
    top: 10px;
    left: 50%;
    margin-left: -176px;
    color: #fff;
    line-height: 20px;
    font-size: 12px;
    color: #d7d9db;
  }
  li {
    border-bottom: 1px solid #f9f9f9;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
    font-size: 13px;
    color: #4c4c4c;
    text-align: left;
    clear: both;
    padding-right: 20px;
    &:first-child {
      border-top: 1px solid #f9f9f9;
    }
    .hold_upload {
      font-size: 12px;
      color: #999999;
      padding-left: 8px;
    }
    .upload_success {
      font-size: 12px;
      color: #999999;
      text-align: left;
      padding-left: 17px;
      position: relative;
      display: inline-block;
      position: relative;
      margin-left: 9px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 19px;
        background: url(./right.png) no-repeat;
        background-size: cover;
        width: 13px;
        height: 10px;
      }
    }
    .symbol_right {
      .repeat_upload {
        color: $cs-primary-color;
        font-size: 13px;
        padding-left: 30px;
      }
    }

  }
  :global(.am-progress) {
    width: 100px;
    height: 6px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    display: inline-block;
    font-size: 0;
    margin-bottom: 0;
  }
  .symbol_progress {
    width: 140px;
    line-height: 1;
    padding-top: 15px;
    .count {
      font-size: 12px;
      display: inline-block;
      line-height: 1;
      padding-left: 12px;
    }
  }
  a{
    display: inline-block;
  }
}

.symbol_file {
  padding-bottom: 15px;
  .file_upload {
    display: inline-block;
    width: 120px;
    height: 32px;
    font-size: 12px;
    color: #273238;
    line-height: 30px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    box-sizing: border-box;
  }
  p {
    display: inline-block;
    font-size: 13px;
    line-height: 30px;
    padding-left: 11px;
    vertical-align: middle;
    a:hover {
      color: $cs-primary-color;
    }
    a{
      display: inline-block;
    }
  }
  .a_ios_upload{
    //padding: 4px 10px;
    width: 125px;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
    height: 35px;
    line-height: 32px;
    position: relative;
    cursor: pointer;
    color: #888;
    background: #fafafa;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    input {
      position: absolute;
      font-size: 100px;
      right: 0;
      top: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      cursor: pointer
    }
  }
  :global(.am-progress) {
    width: 100px;
    height: 6px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    display: inline-block;
    font-size: 0;
    margin-bottom: 0;
  }
  .symbol_progress {
    width: 150px;
    line-height: 1;
    padding-top: 15px;
    .count {
      font-size: 12px;
      display: inline-block;
      line-height: 1;
      padding-left: 12px;
    }
  }
}

.a_upload {
  vertical-align: middle;
  height: 20px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
  color: $cs-primary-color;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer
  }
}

.hide {
  display: none;
}

.cursor_pointer{
  cursor: pointer;
}

.no_need_symbol{
  font-family: $cs-font-light;
  padding: 8px 0;
  color: $cs-sub-text-color;
}

.system_symbol_text {
  border: 1px solid change-color($cs-link-color, $alpha: 0.25);
  padding: 2px 4px;
  border-radius: 4px;
  background-color: change-color($cs-link-color, $alpha: 0.1);
  color: $cs-link-color;
}
