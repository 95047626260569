@import "styles/constant.scss";

.symbol_list {
  position: relative;
  min-height: 300px;

  .empty_tip {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    margin-left: -40px;
    color: gray;
  }

  .pager {
    position: relative;
    height: 100px;
  }
}

.list_item {
  background: #FFF;
  color: #273238;
  height: 42px;
  line-height: 43px;
  font-size: 0;
  overflow: hidden;
  padding-left: 0;
  position: relative;
  .warn {
    color: #F44336;
  }
  a {
    cursor: pointer;
  }
  .highlight {
    a {
      color: $cs-link-color;
    }
    .first {
      margin-right: 10px;
    }
  }
}

.bottom {
  border-bottom: 1px solid #e0e0e0;
}

.successFileName{
  svg {
    path {
      fill: #6669FE;
    }
  }
}

.copy_icon{
  position: relative;
  right: 0;
  top: 1px;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-left: 0;
  color: #6669FE;
  cursor: pointer;
&:hover {
  svg {
    path {
      fill: #A452DD
    }
  }
}
}